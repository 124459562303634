import React, { Component} from 'react'
import { connect } from 'react-redux'
import { Row, Col, Card, Button } from 'react-bootstrap'
import Header from '../Header'
import Spinner from 'react-bootstrap/Spinner'
import { toast } from 'react-toastify';
import 'moment/locale/es';
import data from '../../lib/backend/data'
import { Link } from 'react-router-dom'
import Switch from "react-switch";
import { ordenarProductos } from '../../lib/helpers/helpers'


class EditarCarta extends Component {
    constructor(props){
        super(props)
        this.state = {
            id_carta: this.props.match.params.id,
            user: JSON.parse(localStorage.getItem('bumer_bknd_user')),
            menu: false,
            loadingMenu: true
        }
        this.handleChange = this.handleChange.bind(this)
        this.handleChangeChecked = this.handleChangeChecked.bind(this)
    }

    handleChange(e){
        const { name, value } = e.target
        const { menu } = this.state
        menu[name] = value
        return this.setState({ menu })
    }
    

    componentDidMount(){
        const { id_carta } = this.state
        this.getMenu(id_carta)
    }

    getMenu(id){
        this.setState({ loadingMenu: true })
        return fetch(`${data.urlapi}/api/menus/details?id=${id}`)
        .then(res => res.json())
        .then(res => {
            const categorias = ordenarProductos(res.categorias)
            this.setState({ loadingMenu: false, menu: res.menu, categorias })
        })
        .catch(error => {
            this.setState({ loadingMenu: false })
            toast.error("Error al consultar la información, intente nuevamente", this.state.toaststyle)
        })
    }

    mostrarDatos(){
        const { loadingMenu, menu } = this.state

        if(loadingMenu){
            return <div>
                <Spinner animation='grow' />
                <h3>Cargando información</h3>
            </div>
        }

        return <div>
            
                <Card style={{ width: '100%' }}>
                <Row>
                <Col xs={12}>
                <Card.Body>
                    <p className='mb-0'>Detalles de la carta</p>
                    <h1 className="mb-0">{menu.title}</h1>
                    <hr />
                    {this.formularioEditarCarta()}
                </Card.Body>
                </Col>
                </Row>
                </Card>
        </div>
    }

    guardarCambios(){
        const { menu } = this.state
        const requeridos = [ 'title' ]
        let faltantes = []
        requeridos.map(valor => !menu[valor] ? faltantes.push(valor) : true)
        if(faltantes.length > 0) return toast.error(`Faltan campos por llenar ${faltantes.join(', ')}`)

        this.setState({ actualizando: true })
        return fetch(`${data.urlapi}/api/menus/edit`,{
            method: 'POST',
            body: JSON.stringify(menu),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(res => res.json())
        .then(res => {
            this.setState({ actualizando: false })
            return toast.success('Guardado exitosamente')
        })
        .catch(error => {
            this.setState({ actualizando: false })
            return toast.error("Error al consultar la información, intente nuevamente")
        })
    }

    handleChangeChecked(checked){
        const { menu} = this.state
        menu.active = checked
        return this.setState({ menu })
    }


    formularioEditarCarta(){
        const { menu, actualizando } = this.state
        return <div>
            <Row>
                <Col md={3}>
                <div className='form-group'>
                <label className='form-control-label' >Nombre</label>
                <input className='form-control mb-3' name="title" value={menu.title} placeholder='Nombre de la carta' onChange={this.handleChange} />
                </div>
                </Col>
                
                <Col md={1}>
                <labe className="form-control-label d-block mb-3" >Activo</labe>
                <Switch onChange={this.handleChangeChecked} checked={menu.active} />
                </Col>

                <Col md={3}>
                <div className='form-group'>
                <label className='form-control-label d-block' >Click para guardar</label>
                { actualizando ? <Spinner animation='border' /> : <Button variant="success" style={{ width: '100%' }} onClick={()=>this.guardarCambios()}>GUARDAR CAMBIOS</Button> } 
                </div>
                </Col>
            </Row>
                
                
        </div>
    }
    
    
    render(){
        return(
            <div className="fluid">
       <Header/>
       <div className="container-fluid">
  <div className="row">
    <main className="col-md-12 ms-sm-auto col-lg-12 px-md-4 leftauto">
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
        <Link to="/establecimientos" className='text-dark' ><i className="fas fa-chevron-left"></i> Volver a establecimientos</Link>
      </div>
      {this.mostrarDatos()}
    </main>
  </div>
</div>
    </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        miusuario: state.miusuario
    }
}

export default connect(mapStateToProps)(EditarCarta);