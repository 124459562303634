import React, { Component} from 'react'
import { connect } from 'react-redux'
import { Row, Col, Card, Button } from 'react-bootstrap'
import Header from '../Header'
import Spinner from 'react-bootstrap/Spinner'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import 'moment/locale/es';
import data from '../../lib/backend/data'
import { Link } from 'react-router-dom'
import Moment from 'react-moment';
import 'moment/locale/es';
import Badge from 'react-bootstrap/Badge'
import QRCode from "react-qr-code";

class Mesas extends Component {
    constructor(props){
        super(props)
        this.state = {
            id_establecimiento: this.props.match.params.id,
            user: JSON.parse(localStorage.getItem('bumer_bknd_user')),
            pedidos: [],
            establecimientos: [],
            establecimiento_seleccionado: false,
            tipo_visualizacion: 'pendientes',
            pedido_seleccionado: false,
            modal_pedido_seleccionado: false,
            loading_accion_pedido: false,
            tipo_visualizacion_pedido: 'resumen',
            mesas: []
        }


    }

    componentDidMount(){
        const { id_establecimiento } = this.state
        this.getMesas(id_establecimiento)
    }

    getMesas(idestablecimiento){
        this.setState({ loadingPedidos: true })
        return fetch(`${data.urlapi}/api/mesas/list?id_restaurant=${idestablecimiento}`)
        .then(res => res.json())
        .then(res => {
            this.setState({ loadingPedidos: false, mesas: res })
        })
        .catch(error => {
            this.setState({ loadingPedidos: false })
            toast.error("Error al consultar la información, intente nuevamente", this.state.toaststyle)
        })
    }


    crearMesa(){
        const { id_establecimiento, mesas } = this.state
        this.setState({ loading_accion_pedido: true })
        return fetch(`${data.urlapi}/api/mesas`,{
            method:'POST',
            body: JSON.stringify({
                id_restaurant: id_establecimiento
            }),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(res => res.json())
        .then(res => {
            if(!res){
                toast.error("Sin respuesta del servidor")
            } else if(res.errorMessage){
                toast.error("Sin respuesta del servidor")
            } else if(res._id){ 
                mesas.push(res)
                this.setState({ mesas })
            }
            this.setState({ loading_accion_pedido: false })
        })
        .catch(error => {
            this.setState({ loading_accion_pedido: false })
            toast.error("Error al realizar esta operación, intente nuevamente", this.state.toaststyle)
        })
    }

    contadorIcon(array){
        if(array.length < 1) return false
        return <Badge className='bg-danger text-light' >{array.length}</Badge>
    }

    opcionesPedidos(){
        const { pedidos, tipo_visualizacion } = this.state
        return <Row className='mb-4'>
            <Col xs={3} className="text-center">
                <Card className='p-3'>
                <h6  >En espera {this.contadorIcon(pedidos.filter(pe => pe.status === 0))}</h6>
                <hr />
                {this.visualizarPedidos(pedidos.filter(pe=>pe.status===0),'pendientes')}
                </Card>
            </Col>
            <Col xs={3} className="text-center">
                <Card className='p-3'>
                <h6 >En cocina {this.contadorIcon(pedidos.filter(pe => pe.status === 1))}</h6>
                <hr />
                {this.visualizarPedidos(pedidos.filter(pe=>pe.status===1), 'en-preparacion')}
                </Card>
            </Col>
            <Col xs={3} className="text-center">
                <Card className='p-3'>
                <h6 >Listo para servir {this.contadorIcon(pedidos.filter(pe => pe.status === 2))}</h6>
                <hr />
                {this.visualizarPedidos(pedidos.filter(pe=>pe.status===2), 'listos-para-recoleccion')}
                </Card>
            </Col>
            <Col xs={3} className="text-center">
                <Card className='p-3'>
                <h6  >Servido</h6>
                <hr />
                {this.visualizarPedidos(pedidos.filter(pe=>pe.status===3), 'preparado')}
                </Card>
            </Col>
            
        </Row>
    }

    bgByTipo(tipo){
        if(tipo==='pendientes') return { bg: 'danger', text:'text-light'}
        return { bg: false, text: false }
    }

    visualizarPedidos(pedidos,tipo){

        if(pedidos.length < 1) return <div >
            <h3>Sin pedidos</h3>
            <p>No tienes pedidos en esta sección</p>
        </div>
        

        return <Row>
            <Col xs={12}>
                <h4 className='mb-4'>{pedidos.length} Pedidos</h4>
            </Col>
            {
                pedidos.map((pedido,i) => {                    
                    return <Col xs={12} className="mb-3 hover" key={`pedido-${i}`} onClick={()=>this.setState({ pedido_seleccionado: pedido, modal_pedido_seleccionado: true })}>
                        <Card bg={this.bgByTipo(tipo).bg} className={this.bgByTipo(tipo).text}>
                            <Card.Body>
                            <Row>
                            <Col xs={4}>
                            <h6 className='mb-0'><Moment locale="ES" fromNow>{pedido.createdAt}</Moment></h6>
                            </Col>
                            <Col xs={4}>
                            <h6 className='mb-0'>Pedido {pedido.numero}</h6>
                            </Col>
                            <Col xs={4}>
                            <h6 className='mb-0'>{pedido.nombre}</h6>
                            </Col>
                            </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                })
            }
        </Row>
    }

    mostrarPedidos(){
        const { loadingPedidos, mesas, loading_accion_pedido } = this.state

        if(loadingPedidos){
            return <div>
                <Spinner animation='grow' />
                <h3>Cargando información</h3>
            </div>
        }
        return <div>
            <h4>{mesas.length > 0 ? `${mesas.length} Registros` : "No se encontraron los registros"}</h4>
            {
                loading_accion_pedido ? <Spinner animation="grow" /> : <Button size="sm" onClick={() => this.crearMesa()}>CREAR NUEVA MESA</Button>
            }
            <Row className='mt-3'>
                {
                    mesas.map(mesa => {
                        return <Col md={2}>
                                <Card className='p-3'>
                                <QRCode 
                                    size={150}
                                    value={mesa._id} 
                                />
                                <h4 className='mt-3'>Mesa {mesa.id}</h4>
                                </Card>
                            </Col>
                    })
                }
            </Row>
        </div>

    }
    
    render(){
        const { id_establecimiento } = this.state
        return(
            <div className="fluid">
       <Header/>
       <div className="container-fluid">
  <div className="row">
    <main className="col-md-12 ms-sm-auto col-lg-12 px-md-4 leftauto">
    <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
      <Link to={`/establecimiento-${id_establecimiento}`} className='text-dark' ><i className="fas fa-chevron-left"></i> Volver</Link>
      </div>
      <h1 className="h2 mb-4"> Mesas </h1>
      {this.mostrarPedidos()}
    </main>
  </div>
</div>
    </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        miusuario: state.miusuario
    }
}

export default connect(mapStateToProps)(Mesas);