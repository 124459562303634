import React, { Component} from 'react'
import { connect } from 'react-redux'
import { Row, Col, Button, Card } from 'react-bootstrap'
import Header from '../Header'
import Spinner from 'react-bootstrap/Spinner'
import { toast } from 'react-toastify';
import 'moment/locale/es';
import data from '../../lib/backend/data'
import { confirmAlert } from 'react-confirm-alert'; // Import
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import { ordenarProductos } from '../../lib/helpers/helpers'
import Switch from "react-switch";
import CargaImagenes from '../../subComponents/general/carga_imagenes'

const peso_maximo = 1000000
const process = { env: { } }

const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {props.titulo}
    </Tooltip>
  );

class EstablecimientoEditar extends Component {
    constructor(props){
        super(props)
        this.state = {
            id_establecimiento: this.props.match.params.id,
            user: JSON.parse(localStorage.getItem('bumer_bknd_user')),
            actualizando: false,
            establecimiento: false,
            loadingEstablecimiento: false,
            subiendofoto: false,
            configs3: {
                dirName: "movil/",
                bucketName: process.env.S3_BUCKET_NAME,
                region: process.env.S3_BUCKET_REGION,
                accessKeyId: process.env.S3_ACCESS_KEY_ID,
                secretAccessKey: process.env.S3_SECRET_ACCESS_KEY,
            },
            carrusel: [],
            subiendobanner: false,
            borrandoBanner: false,
            posiciones_actualizadas: false,
            loadingReordenar: false,
            configuracion: []
        }
        this.handleChange = this.handleChange.bind(this)
        this.handleChangeChecked = this.handleChangeChecked.bind(this)

    }

    handleChangeChecked(checked){
        const { establecimiento } = this.state
        establecimiento.cerrado = checked
        return this.setState({ establecimiento })
    }

    componentDidMount(){
        console.log(this.state.configs3)
        const { id_establecimiento } = this.state
        this.getEstablecimiento(id_establecimiento)
        this.getCarruselEstablecimiento(id_establecimiento)
    }

    getCarruselEstablecimiento(id){
        return fetch(`${data.urlapi}/api/establecimientos/carrusel?id=${id}`)
        .then(res => res.json())
        .then(res => {
            const carrusel = ordenarProductos(res)
            this.setState({ carrusel })
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente", this.state.toaststyle)
        })
    }

    getEstablecimiento(id){
        this.setState({ loadingEstablecimiento: true })
        return fetch(`${data.urlapi}/api/establecimientos/details?id=${id}`)
        .then(res => res.json())
        .then(res => {
            console.log(res)
            this.setState({ loadingEstablecimiento: false, establecimiento: res.dato, configuracion: res.configuracion })
        })
        .catch(error => {
            this.setState({ loadingEstablecimiento: false })
            toast.error("Error al consultar la información, intente nuevamente", this.state.toaststyle)
        })
    }

    handleChange(e){
        const { name, value } = e.target
        const { establecimiento } = this.state
        establecimiento[name] = value
        return this.setState({ establecimiento })
    }

    guardarCambios(){
        const { establecimiento } = this.state

        const requeridos = [ 'titulo' ]
        let faltantes = []
        requeridos.map(campo => {
            if(!establecimiento[campo]) faltantes.push(campo)
            return false
        })
        if(faltantes.length > 0){
            return toast.error(`Faltan los campos: ${faltantes.join(', ')}`)
        }

        this.setState({ actualizando: true })
        return fetch(`${data.urlapi}/api/establecimientos/update`,{
            method: 'POST',
            body: JSON.stringify(establecimiento),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(res => res.json())
        .then(res => {
            this.setState({ actualizando: false })
            return toast.success('Guardado exitosamente')
        })
        .catch(error => {
            this.setState({ actualizando: false })
            return toast.error("Error al consultar la información, intente nuevamente")
        })

    }

    async confirmarClonacion(id){
        this.setState({ loadingEstablecimiento: true })
        return fetch(`${data.urlapi}/api/establecimientos/clonar?id=${id}`)
        .then(res => res.json())
        .then(res => {
            if(!res){
                toast.error("Sin respuesta del servidor")
                return this.setState({ loadingEstablecimiento: false })
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return this.setState({ loadingEstablecimiento: false })
            } else if(res._id){
                toast.success("Clonado exitosamente, espera mientras te redirigimos")
                return setTimeout(() => {
                    return window.location = `/establecimiento-${res._id}`
                }, 500);
            }
            return true
        })
        .catch(error => {
            this.setState({ loadingEstablecimiento: false })
            return toast.error("Error al consultar la información, intente nuevamente")
        })

    }

    requiredIcon(){
        return <span style={{ fontSize: 15 }} className='text-danger' >*</span>
    }


    nuevoBanner(url){
        const { id_establecimiento, carrusel } = this.state
        const banner = {
            id_establecimiento,
            url,
            active: true
        }
        return fetch(`${data.urlapi}/api/establecimientos/new-banner`,{
            method: 'POST',
            body: JSON.stringify(banner),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(res => res.json())
        .then(res => {
            if(!res) return toast.error('No se pudo guardar')
            carrusel.push(res)
            this.setState({ carrusel })
            return toast.success('Guardado exitosamente')
        })
        .catch(error => {
            return toast.error("Error al consultar la información, intente nuevamente")
        })
    }

    moverPosicion(pos,accion){
        const { carrusel, posiciones_actualizadas } = this.state
        if(!posiciones_actualizadas) toast.info('Al terminar de ordenar los items has click en GUARDAR ORDEN')
        if(accion==='subir'){
            const anterior = carrusel[pos-1]
            carrusel[pos-1] = carrusel[pos]
            carrusel[pos] = anterior
        } else if(accion==='bajar'){
            const anterior_pro = carrusel[pos+1]
            carrusel[pos+1] = carrusel[pos]
            carrusel[pos] = anterior_pro
        }
        return this.setState({ carrusel, posiciones_actualizadas: true })
    }

    guardarOrdenCarrusel(){
        const { carrusel } = this.state
        let registros = []
        carrusel.map((pro,i) => {
            return registros.push({ _id: pro._id, orden: i })
        })
        this.setState({ loadingReordenar: true })
        return fetch(`${data.urlapi}/api/establecimientos/carrusel-reorder`,{
            method: 'POST',
            body: JSON.stringify(registros),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(res => res.json())
        .then(res => {
            this.setState({ loadingReordenar: false, posiciones_actualizadas: false })
            return toast.success('Guardado exitosamente')
        })
        .catch(error => {
            this.setState({ loadingReordenar: false })
            toast.error("Error al consultar la información, intente nuevamente")
        })
    }

    accionesGuardarCarrusel(){
        const { posiciones_actualizadas, loadingReordenar } = this.state
        if(loadingReordenar) return <div>
        <Spinner animation='grow' />
        <h3>Cargando información</h3>
        </div>
        
        if(!posiciones_actualizadas) return false

        return <Button className="mb-3" size="sm" variant="success" onClick={()=>this.guardarOrdenCarrusel()} ><i className="fas fa-random"></i> GUARDAR ORDEN</Button>
    }

    visualizarCarrusel(carrusel){
        if(carrusel.length < 1) return <Row className="justify-content-md-center">
        <Col md={6} className="text-center p-3">
            <img alt="banners" src="images/banners.png" width={180} />
        <h4>No has agregado tu primera imagen</h4>
        <p>Puedes agregar hasta 6 imágenes para visualizarlo en la portada de tu sitio web</p>
        </Col>
    </Row>

        return <div className='mt-3'>
            {this.accionesGuardarCarrusel()}
            <h4>{carrusel.length} Imágenes</h4>
            <Row className='mt-3 mb-3'>
            {
                carrusel.map((banner,i) => {
                    return <Col md={3} key={`banner${i}`} >
                        <Row>
                        <Col md={6}>{ i === 0 ? false : 
                        <OverlayTrigger
                            placement="right"
                            delay={{ show: 250, hide: 400 }}
                            overlay={renderTooltip({ titulo: "Subir de posición"})}
                        >
                        <i className="fas fa-arrow-up hover" onClick={()=>this.moverPosicion(i,'subir')} ></i>
                        </OverlayTrigger> 
                        }</Col>
                        <Col md={6} className='text-right' >{ i+1 === carrusel.length ? false :
                        <OverlayTrigger
                        placement="right"
                        delay={{ show: 250, hide: 400 }}
                        overlay={renderTooltip({ titulo: "Bajar de posición"})}
                        >
                        <i className="fas fa-arrow-down hover" onClick={()=>this.moverPosicion(i,'bajar')}></i>
                        </OverlayTrigger>   }</Col>
                        </Row>
                        <img alt="banner" style={{ width: "100%" }} src={banner.url} className='mb-3' />
                        <Button variant="danger" size="sm" onClick={()=>this.solicitarEliminadoBanner(banner._id)} >ELIMINAR</Button>
                    </Col>
                })
            }
        </Row>
        </div>
    }

    solicitarEliminadoBanner(id){
        return confirmAlert({
            title: '¿Estás seguro?',
            message: `¿Deseas eliminar este item? Esta opción no se puede deshacer`,
            buttons: [
              {
                label: 'CONFIRMAR',
                onClick: () => this.eliminarBanner(id)
              },
              {
                label: 'CANCELAR',
                onClick: () => false
              },
            ],
          })
    }

    eliminarBanner(id){
        const { carrusel } = this.state
        this.setState({ borrandoBanner: true })
        return fetch(`${data.urlapi}/api/establecimientos/delete-carrusel?id=${id}`)
        .then(res => res.json())
        .then(res => {
            const i = carrusel.findIndex(pro => pro._id === id)
            if(i > -1){
                carrusel.splice(i,1)
                this.setState({ carrusel })
            }
            toast.success('Borrado exitosamente')
            this.setState({ borrandoBanner: false })
        })
        .catch(error => {
            this.setState({ borrandoBanner: false })
            toast.error("Error al consultar la información, intente nuevamente", this.state.toaststyle)
        })
    }

    clonarEstablecimiento(id){
        return confirmAlert({
            title: '¿Estás seguro?',
            message: `Estás a punto de crear una copia de este establecimiento, métodos de envío, carta, productos, adicionales, métodos de pago y tarifas de envío. Esto podría incurrir en gastos adicionales en tu cuenta.`,
            buttons: [
              {
                label: 'CONFIRMAR',
                onClick: () => this.confirmarClonacion(id)
              },
              {
                label: 'CANCELAR',
                onClick: () => false
              },
            ],
          })
    }

    formasDePago(){
        const { configuracion, establecimiento } = this.state

        if(establecimiento.tipos_pago) return <Col xs={12}>

        


        </Col>


        if(configuracion.length < 1) return false
        const i = configuracion.findIndex(conf => conf.slug==='tipos-pago')
        if(i < 0) return false

    }

    formularioCreacion(){
        const { actualizando, establecimiento, subiendobanner, carrusel, subiendofoto, loadingEstablecimiento } = this.state

        if(loadingEstablecimiento){
            return <div>
                <Spinner animation='grow' />
                <h3>Cargando información</h3>
            </div>
        }

        if(!establecimiento) return <h4>No pudimos encontrar este establecimiento</h4>

        return <div className='mb-5'>

            <Row>

            <Col md={12} >
                <h4><i className="fas fa-store-alt"></i> Detalles</h4>
            </Col>

            <Col md={3} >
                <label className='form-control-label' >Nombre fantasía {this.requiredIcon()}</label>
                <input className='form-control' name="titulo" defaultValue={establecimiento.titulo} onChange={this.handleChange} />
            </Col>
            
            <Col md={3} >
                <label className='form-control-label' >Whatsapp</label>
                <input className='form-control' name="telefono" defaultValue={establecimiento.telefono} onChange={this.handleChange} />
            </Col>

            <Col md={3} >
                <label className='form-control-label' >Dirección</label>
                <input className='form-control' name="direccion" defaultValue={establecimiento.direccion} onChange={this.handleChange} />
            </Col>

            <Col md={3} >
                <label className='form-control-label' >Tiempo de entrega</label>
                <input className='form-control' name="tiempo_espera" defaultValue={establecimiento.tiempo_espera} onChange={this.handleChange} />
            </Col>

            <Col md={2} className="mb-3">
                <label className='form-control-label' >Color principal</label>
                <input type="color" className='form-control' name="color" defaultValue={establecimiento.color} onChange={this.handleChange} />
            </Col>   

            <Col md={12} >
                <label className='form-control-label d-block' >Hacer una copia de este establecimiento</label>
                <Button className='p-0' variant="link" onClick={()=>this.clonarEstablecimiento(establecimiento._id)}>CLONAR</Button>
            </Col>           

            <Col md={12} className='mt-3' >
                <h4><i className="far fa-thumbs-up"></i> Redes sociales</h4>
            </Col>

            <Col md={3} >
                <label className='form-control-label' >Facebook (Link)</label>
                <input className='form-control' name="facebook" defaultValue={establecimiento.facebook} onChange={this.handleChange} />
            </Col>

            <Col md={3} >
                <label className='form-control-label' >Instagram (Usuario sin @)</label>
                <input className='form-control' name="instagram" defaultValue={establecimiento.instagram} onChange={this.handleChange} />
            </Col>

            <Col md={3} >
                <label className='form-control-label' >Twitter (Link)</label>
                <input className='form-control' name="twitter" defaultValue={establecimiento.twitter} onChange={this.handleChange} />
            </Col>

            <Col md={12} className='mt-3' >
                <h4><i className="far fa-clock"></i> Horarios</h4>
            </Col>
            <Col md={12} className="mb-3" >
                <Card>
                    <div className='p-3'>
                        <h5 className='mb-0'>Cerrar pedidos</h5>
                        <p className='mb-0'>Al activar esta opción no permitirás que ingresen pedidos</p>
                        <Switch onChange={this.handleChangeChecked} checked={establecimiento.cerrado} />
                    </div>
                </Card>
            </Col>

            <Col md={3} className='mb-3'>
            <h6><i className="fas fa-arrow-circle-right text-primary"></i> Lunes</h6>
            <Row>
                    <Col xs={6}>
                        <label className='form-control-label' >Desde</label>
                        <input className='form-control' name="lunes_desde" defaultValue={establecimiento.lunes_desde} type="time" onChange={this.handleChange} />
                    </Col>
                    <Col xs={6}>
                        <label className='form-control-label' >Hasta</label>
                        <input className='form-control' name="lunes_hasta" defaultValue={establecimiento.lunes_hasta} type="time" onChange={this.handleChange} />
                    </Col>
                </Row>
            </Col>

            <Col md={3} className='mb-3'>
            <h6><i className="fas fa-arrow-circle-right text-primary"></i> Martes</h6>
            <Row>
                    <Col xs={6}>
                        <label className='form-control-label' >Desde</label>
                        <input className='form-control' name="martes_desde" defaultValue={establecimiento.martes_desde} type="time" onChange={this.handleChange} />
                    </Col>
                    <Col xs={6}>
                        <label className='form-control-label' >Hasta</label>
                        <input className='form-control' name="martes_hasta" defaultValue={establecimiento.martes_hasta} type="time" onChange={this.handleChange} />
                    </Col>
                </Row>
            </Col>

            <Col md={3} className='mb-3'>
            <h6><i className="fas fa-arrow-circle-right text-primary"></i> Miercoles</h6>
            <Row>
                    <Col xs={6}>
                        <label className='form-control-label' >Desde</label>
                        <input className='form-control' name="miercoles_desde" defaultValue={establecimiento.miercoles_desde} type="time" onChange={this.handleChange} />
                    </Col>
                    <Col xs={6}>
                        <label className='form-control-label' >Hasta</label>
                        <input className='form-control' name="miercoles_hasta" defaultValue={establecimiento.miercoles_hasta} type="time" onChange={this.handleChange} />
                    </Col>
                </Row>
            </Col>

            <Col md={3} className='mb-3'>
            <h6><i className="fas fa-arrow-circle-right text-primary"></i> Jueves</h6>
            <Row>
                    <Col xs={6}>
                        <label className='form-control-label' >Desde</label>
                        <input className='form-control' name="jueves_desde" defaultValue={establecimiento.jueves_desde} type="time" onChange={this.handleChange} />
                    </Col>
                    <Col xs={6}>
                        <label className='form-control-label' >Hasta</label>
                        <input className='form-control' name="jueves_hasta" defaultValue={establecimiento.jueves_hasta} type="time" onChange={this.handleChange} />
                    </Col>
                </Row>
            </Col>

            <Col md={3} className='mb-3'>
            <h6><i className="fas fa-arrow-circle-right text-primary"></i> Viernes</h6>
            <Row>
                    <Col xs={6}>
                        <label className='form-control-label' >Desde</label>
                        <input className='form-control' name="viernes_desde" defaultValue={establecimiento.viernes_desde} type="time" onChange={this.handleChange} />
                    </Col>
                    <Col xs={6}>
                        <label className='form-control-label' >Hasta</label>
                        <input className='form-control' name="viernes_hasta" defaultValue={establecimiento.viernes_hasta} type="time" onChange={this.handleChange} />
                    </Col>
                </Row>
            </Col>

            <Col md={3} className='mb-3'>
            <h6><i className="fas fa-arrow-circle-right text-primary"></i> Sábado</h6>
            <Row>
                    <Col xs={6}>
                        <label className='form-control-label' >Desde</label>
                        <input className='form-control' name="sabado_desde" defaultValue={establecimiento.sabado_desde} type="time" onChange={this.handleChange} />
                    </Col>
                    <Col xs={6}>
                        <label className='form-control-label' >Hasta</label>
                        <input className='form-control' name="sabado_hasta" defaultValue={establecimiento.sabado_hasta} type="time" onChange={this.handleChange} />
                    </Col>
                </Row>
            </Col>

            <Col md={3} className='mb-3'>
            <h6><i className="fas fa-arrow-circle-right text-primary"></i> Domingo</h6>
            <Row>
                    <Col xs={6}>
                        <label className='form-control-label' >Desde</label>
                        <input className='form-control' name="domingo_desde" defaultValue={establecimiento.domingo_desde} type="time" onChange={this.handleChange} />
                    </Col>
                    <Col xs={6}>
                        <label className='form-control-label' >Hasta</label>
                        <input className='form-control' name="domingo_hasta" defaultValue={establecimiento.domingo_hasta} type="time" onChange={this.handleChange} />
                    </Col>
                </Row>
            </Col>

            {this.formasDePago()}

            <Col md={12} className='mt-3' >
                <h4><i className="far fa-images"></i> Imágenes</h4>
            </Col>

            <Col md={6}>
                <label className='form-control-label' >Logotipo</label>
                <CargaImagenes onUploaded={(url) => {
                    establecimiento.logotipo = url
                    return this.setState({ establecimiento })
                }} />
                { subiendofoto ? <Spinner animation='border' /> : false } 
                { establecimiento.logotipo ? <div>
                    <img alt="logotipo" className='d-block pt-3 pb-3' src={establecimiento.logotipo} width={200} />
                    <Button variant='outline-danger' className="mb-3" size="sm">ELIMINAR</Button>
                </div> : false }
            </Col>

            <Col md={12}  >
                <hr className='mt-2' />
                <label className='form-control-label' >Carrusel de imágenes del sitio web</label>   
                <h5>Agregar nueva foto</h5>
                { subiendobanner ? <Spinner animation='border' /> : false } 
                <CargaImagenes onUploaded={async (url) => {
                    await this.nuevoBanner(url)
                }} />
                {this.visualizarCarrusel(carrusel)}
            </Col>

            <Col md={12} className='mt-3' >
                { actualizando ? <div><Spinner animation='grow' /><h3>Cargando</h3></div> : <Button size="sm" variant="success" onClick={()=>this.guardarCambios()} >GUARDAR CAMBIOS</Button>}
            </Col>

            </Row>

        </div>
    }

    render(){
        return(
            <div className="fluid">
       <Header/>  
       <div className="container-fluid">
    <div className="row">
    <main className="col-md-12 ms-sm-auto col-lg-12 px-md-4 leftauto">
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
        <h1 className="h2"> Editar Establecimiento </h1>
      </div>
      {this.formularioCreacion()}
    </main>
  </div>
</div>
    </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        miusuario: state.miusuario
    }
}

export default connect(mapStateToProps)(EstablecimientoEditar);