import React, { Component} from 'react'
import { connect } from 'react-redux'
import { Row, Col, Card, Button } from 'react-bootstrap'
import Header from '../Header'
import Spinner from 'react-bootstrap/Spinner'
import { toast } from 'react-toastify';
import 'moment/locale/es';
import data from '../../lib/backend/data'
import { Link } from 'react-router-dom'

class Establecimientos extends Component {
    constructor(props){
        super(props)
        this.state = {
            user: JSON.parse(localStorage.getItem('bumer_bknd_user')),
            establecimientos: [],
            loadingEstablecimientos: true,
            toaststyle: { position: toast.POSITION.BOTTOM_RIGHT },
            primer_registro: false,
            filtro: '',
            eliminando: false
        }
    }

    componentDidMount(){
        const { user } = this.state
        this.getEstablecimientos(user._id)
    }

    getEstablecimientos(id_user){
        this.setState({ loadingEstablecimientos: true })
        return fetch(`${data.urlapi}/api/establecimientos/list?id_user=${id_user}`)
        .then(res => res.json())
        .then(res => {
            this.setState({ loadingEstablecimientos: false, establecimientos: res, primer_registro: res.length > 0 ? false : true })
        })
        .catch(error => {
            this.setState({ loadingEstablecimientos: false })
            toast.error("Error al consultar la información, intente nuevamente", this.state.toaststyle)
        })
    }

    sinRegistros(){
        return <div className='p-5'>
            <Row className="justify-content-md-center">
                <Col md={6} className="text-center">
                <h4>No has creado tu primer establecimiento</h4>
                <p>Un establecimiento es un lugar físico donde ofreces tus productos, restaurante, bar o restobar. Una vez que lo crees podrás crear una o varias cartas online para que tus clientes puedan acceder a ellas con tu código QR</p>
                <Link to="/crear-establecimiento" className='text-white' ><Button variant="primary" >CREAR ESTABLECIMIENTO</Button></Link>
                </Col>
            </Row>
        </div>
    }

    mostrarDatos(){
        const { loadingEstablecimientos, establecimientos, filtro, primer_registro } = this.state

        if(primer_registro===true) return this.sinRegistros()

        const lowercasedFilter = filtro.toLowerCase();
        const listasFiltradas = establecimientos.filter(item => {
            return Object.keys(item).some(key =>
                item[key].toString().toLowerCase().includes(lowercasedFilter)
            )
        })

        if(loadingEstablecimientos){
            return <div>
                <Spinner animation='grow' />
                <h3>Cargando información</h3>
            </div>
        }

        return <div>
          <Row>

            <Col xs={12}>
        <div>
<h4>{establecimientos.length} Registros</h4>
<Link to="/crear-establecimiento" className='text-white' ><Button size="sm" variant="primary" >CREAR SUCURSAL</Button></Link>

{filtro !== ''  ? <Col xs={12}><label className="form-control-label">{listasFiltradas.length} Coincicencias</label></Col>:false}
<Row className='mt-3'>
{ listasFiltradas.map((lista,i)=>{
        return <Col md={3} key={`user${i}`}>
            <Card className='p-3'>
            <h3 className="mb-0">{lista.titulo}</h3>
            <p className="mb-0">{lista.direccion}</p>
            <Link to={`establecimiento-${lista._id}`}>Ver detalles</Link>
            </Card>
        </Col>
    }) }
    </Row>
        </div>
            </Col>

          
          </Row>          
          </div>
    }

    
    render(){
        return(
            <div className="fluid">
       <Header/>
<div className="container-fluid">
  <div className="row">
    <main className="col-md-12 ms-sm-auto col-lg-12 px-md-4 leftauto">
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
        <h1 className="h2"> Sucursales </h1>
      </div>
      {this.mostrarDatos()}
    </main>
  </div>
</div>
    </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        miusuario: state.miusuario
    }
}

export default connect(mapStateToProps)(Establecimientos);