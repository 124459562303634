import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import Login from './components/Login';
import store from './redux/store';
import reportWebVitals from './reportWebVitals'
import 'bootstrap/dist/css/bootstrap.min.css'
import Home from './components/Home';
import { PrivateRoute } from './components/PrivateRoute';
import Planes from './components/Planes';
import PayPlan from './components/PayPlan';
import MesasQR from './components/MesasQR';
import Establecimientos from './components/Establecimientos';
import EstablecimientoCrear from './components/EstablecimientoCrear';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import EstablecimientoDetalles from './components/EstablecimientoDetalles';
import CartaDetalles from './components/CartaDetalles';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import EditarCarta from './components/EditarCarta';
import EstablecimientoEditar from './components/EstablecimientoEditar';
import CentroPedidos from './components/CentroPedidos';
import Adicionales from './components/Adicionales';
import Shipping from './components/Shipping';
import Payments from './components/Payments';
import ShippingMethods from './components/ShippingMethods';
import Clientes from './components/Clientes';
import Mesas from './components/Mesas';
import Garzones from './components/Garzones';
import Usuarios from './components/Usuarios';
import UsuariosCrear from './components/UsuariosCrear';
import UsuariosEditar from './components/UsuariosEditar';

require('dotenv').config()

const App = (<div>
  <ToastContainer
          position="bottom-center"
          toastStyle={{ backgroundColor: "black" }}
          hideProgressBar={true}
  />
  <Provider store={store}>
    <BrowserRouter>
    <Switch>
      <PrivateRoute exact path="/" component={Home} />
      <PrivateRoute exact path="/plans" component={Planes} />
      <PrivateRoute exact path="/pay-plan-:id" component={PayPlan} />
      <PrivateRoute exact path="/codigos" component={MesasQR} />
      <PrivateRoute exact path="/orderscenter-:id" component={CentroPedidos} />
      <PrivateRoute exact path="/establecimientos" component={Establecimientos} />
      <PrivateRoute exact path="/crear-establecimiento" component={EstablecimientoCrear} />
      <PrivateRoute exact path="/garzones-:id" component={Garzones} />
      <PrivateRoute exact path="/mesas-:id" component={Mesas} />
      <PrivateRoute exact path="/establecimiento-:id" component={EstablecimientoDetalles} />
      <PrivateRoute exact path="/editar-establecimiento-:id" component={EstablecimientoEditar} />
      <PrivateRoute exact path="/carta-:id" component={CartaDetalles} />
      <PrivateRoute exact path="/editar-carta-:id" component={EditarCarta} />
      <PrivateRoute exact path="/adicionales-:id" component={Adicionales} />
      <PrivateRoute exact path="/usuarios/nuevo" component={UsuariosCrear} />
      <PrivateRoute exact path="/usuarios/edit-:id" component={UsuariosEditar} />
      <PrivateRoute exact path="/usuarios" component={Usuarios} />
      <PrivateRoute exact path="/customers-:id" component={Clientes} />
      <PrivateRoute exact path="/shipping" component={Shipping} />
      <PrivateRoute exact path="/payments-:id" component={Payments} />
      <PrivateRoute exact path="/shippingmethods-:id" component={ShippingMethods} />
      <Route path="/login" component={Login} />
      
    </Switch>
  </BrowserRouter>
  </Provider>
  </div>) 

ReactDOM.render(App, document.getElementById('root'))
reportWebVitals();
