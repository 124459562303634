import React, { Component} from 'react'
import { connect } from 'react-redux'
import { Card } from 'react-bootstrap'
import Header from '../Header'
import 'react-toastify/dist/ReactToastify.css'
import { Row, Col } from 'react-bootstrap'

class Home extends Component {
    constructor(){
        super()
        this.state = {
            username: '',
            password: '',
            submitted: false,
            loading: false,
            errorLogin: '',
            user: JSON.parse(localStorage.getItem('bumer_bknd_user')),
            loadinguser: true,
            loadinginsignias: true,
            insignias: []
        }
    }

    ifactive(status){
      switch(status){
        case '1':
          return <p className="nomargin" style={{ fontSize: 12 }} ><i style={{ color: '#00b23d'}} className="fas fa-check-circle"></i> ACTIVO</p>
        default:
            return <p className="nomargin" style={{ fontSize: 12 }} ><i className="far fa-clock"></i> INACTIVO</p>
      }
    }
    
    misDatos(){
      const { user } = this.state
      return <Row className="bienvenida">

      </Row>

    }

    render(){
        const { user } = this.state
        return(
            <div className="fluid">
       <Header/>   
       <div className="container-fluid">
  <div className="row">
    <main className="col-md-12 ms-sm-auto col-lg-12 px-md-4 leftauto">
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center text-center pt-3 pb-2 ">
      <h3 style={{ fontWeight:100 }}>Bienvenido {user ?  <b>{user.nombres}</b> : false } </h3>
        
      </div>


      {
        this.misDatos()
      }
    </main>
  </div>
</div>
    </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        miusuario: state.miusuario
    }
}

export default connect(mapStateToProps)(Home);