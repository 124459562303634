import React, { Component} from 'react'
import { connect } from 'react-redux'
import { Row, Col, Card, Button } from 'react-bootstrap'
import Header from '../Header'
import Spinner from 'react-bootstrap/Spinner'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import 'moment/locale/es';
import data from '../../lib/backend/data'
import { ListGroup } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import 'moment/locale/es';
import { confirmAlert } from 'react-confirm-alert'; // Import

class Adicionales extends Component {
    constructor(props){
        super(props)
        this.state = {
            user: JSON.parse(localStorage.getItem('bumer_bknd_user')),
            id_establecimiento: this.props.match.params.id,
            establecimientos: [],
            establecimiento_seleccionado: false,
            regiones: [],
            comunas: [],
            idregiondestino: false,
            idcomunadestino: false,
            nueva_tarifa: {
                precio: 0
            },
            creandoAdicional: false,
            adicionales: [],
            loadingAdicionales: true,
            comunas_descartar_creacion: [],
            adicionales_categoria: [],
            creandoAdicionalCategoria: false,
            nuevo_adicional: {},
            nuevo_adicional_categoria: {},
            eliminando: false,
            actualizando: false
        }

        this.handleChange = this.handleChange.bind(this)
        this.handleChangeAdicional = this.handleChangeAdicional.bind(this)
        this.handleChangeSelectOrigenRegion = this.handleChangeSelectOrigenRegion.bind(this)
        this.handleChangeSelectOrigen = this.handleChangeSelectOrigen.bind(this)
        this.handleChangeEstablecimiento = this.handleChangeEstablecimiento.bind(this)
        this.handleChangeAdicionalCategoria = this.handleChangeAdicionalCategoria.bind(this)
        this.handleChangeAdicionalEditar = this.handleChangeAdicionalEditar.bind(this)
    }

    handleChange(e){
        const { name, value } = e.target
        return this.setState({ [name]: value })
    }

    handleChangeAdicionalCategoria(e){
        const { nuevo_adicional_categoria } = this.state
        const { name, value } = e.target
        nuevo_adicional_categoria[name] = value
        return this.setState({ nuevo_adicional_categoria })
    }

    handleChangeAdicional(e){
        const { nuevo_adicional } = this.state
        const { name, value } = e.target
        nuevo_adicional[name] = value
        return this.setState({ nuevo_adicional })
    }

    handleChangeAdicionalEditar(e){
        const { adicionales } = this.state
        const { name, value } = e.target
        const id_cat = e.target.getAttribute('id_cat')
        const i = adicionales.findIndex(ad => ad._id === id_cat)
        if(i < 0) return false
        adicionales[i][name] = value
        return this.setState({ adicionales })
    }

    async componentDidMount(){
        const { id_establecimiento, user } = this.state
        await this.getEstablecimientos(user._id)
        await this.getAdicionales(id_establecimiento)
        await this.getAdicionalesCategoria(id_establecimiento)
    }

    handleClose(){
        return this.setState({ modal_pedido_seleccionado: false, tipo_visualizacion_pedido: 'resumen' })
    }


    eliminarTarifa(){
        const { nueva_tarifa, establecimiento_seleccionado, pais, idregiondestino, idcomunadestino } = this.state
        nueva_tarifa.id_establecimiento = establecimiento_seleccionado
        nueva_tarifa.destino = {
            idpais: pais._id,
            idregion: idregiondestino,
            idcomuna: idcomunadestino
        }
        const loading = 'loadingEsquemaPais'
        this.setState({ [loading]: true })
        return fetch(`${data.urlapi}/api/tarifas`,{
            method:'POST',
            body: JSON.stringify( nueva_tarifa ),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(res => res.json())
        .then(res => {
            if(res._id){ 
                /*
                const i = pedidos.findIndex(pe => pe._id === id)
                if(i > -1){
                    pedidos[i].status = status
                    this.setState({ modal_pedido_seleccionado: false })
                }
                */
                toast.success('Realizado exitosamente')
            }
            this.setState({ loading_accion_pedido: false })
        })
        .catch(error => {
            this.setState({ loading_accion_pedido: false })
            toast.error("Error al realizar esta operación, intente nuevamente", this.state.toaststyle)
        })
    }

    guardarCambiosAdicional(id){
        const { adicionales } = this.state
        const i = adicionales.findIndex(ad => ad._id === id)
        if(i < 0) return false
        const loading = 'actualizando'
        this.setState({ [loading]: true })
        return fetch(`${data.urlapi}/api/adicionales/update-adicional`,{
            method:'POST',
            body: JSON.stringify( adicionales[i] ),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(res => res.json())
        .then(res => {
            if(!res){
                toast.error("Sin datos del servidor")
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
            } else if(res._id){ 
                toast.success('Guardado exitosamente')
            }
            this.setState({ [loading]: false })
        })
        .catch(error => {
            this.setState({ [loading]: false })
            toast.error("Error al realizar esta operación, intente nuevamente", this.state.toaststyle)
        })
    }

    crearAdicionalCategoria(){
        const { nuevo_adicional_categoria, establecimiento_seleccionado, id_establecimiento, adicionales_categoria } = this.state
        nuevo_adicional_categoria.id_establecimiento = id_establecimiento
        if(!nuevo_adicional_categoria.titulo) return toast.error("Título es inválido")
        const loading = 'creandoAdicionalCategoria'
        this.setState({ [loading]: true })
        return fetch(`${data.urlapi}/api/adicionales/adicionales-categoria`,{
            method:'POST',
            body: JSON.stringify( nuevo_adicional_categoria ),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(res => res.json())
        .then(res => {
            if(!res){
                toast.error("Sin datos del servidor")
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
            } else if(res._id){ 
                adicionales_categoria.unshift(res)
                this.setState({ adicionales_categoria })
                toast.success('Guardado exitosamente')
                nuevo_adicional_categoria.titulo = ''
                this.setState({ nuevo_adicional_categoria })
            }
            return this.setState({ [loading]: false })
        })
        .catch(error => {
            this.setState({ [loading]: false })
            return toast.error("Error al realizar esta operación, intente nuevamente", this.state.toaststyle)
        })
    }

    crearAdicional(){
        const { nuevo_adicional, establecimiento_seleccionado, adicionales } = this.state
        nuevo_adicional.id_establecimiento = establecimiento_seleccionado
        const requeridos = [
            'id_categoria', 'titulo', 'precio', 'disponible'
        ]
        let faltantes = []
        requeridos.map(valor => {
            if(!nuevo_adicional[valor]) faltantes.push(valor)
            return true
        })
        if(faltantes.length > 0) return toast.error(`Falta llenar los campos: ${faltantes.join(', ')}`)

        const loading = 'creandoAdicional'
        this.setState({ [loading]: true })
        return fetch(`${data.urlapi}/api/adicionales`,{
            method:'POST',
            body: JSON.stringify( nuevo_adicional ),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(res => res.json())
        .then(res => {
            if(!res){
                toast.error("Sin datos del servidor")
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
            } else if(res._id){ 
                adicionales.unshift(res)
                this.setState({ adicionales })
                toast.success('Guardado exitosamente')
            }
            this.setState({ [loading]: false })
        })
        .catch(error => {
            this.setState({ [loading]: false })
            toast.error("Error al realizar esta operación, intente nuevamente", this.state.toaststyle)
        })
    }

    getAdicionalesCategoria(id){
        const loading = 'loadingAdicionalesCategoria'
        this.setState({ [loading]: true })
        return fetch(`${data.urlapi}/api/adicionales/list-adicional-categoria?idestablecimiento=${id}`)
        .then(res => res.json())
        .then(res => {
            if(!res){
                toast.error('No se obtuvieron datos del servidor')
                return this.setState({ [loading]: false })
            }
            if(res.errorMessage){
                toast.error(res.errorMessage)
                return this.setState({ [loading]: false })
            }
            this.setState({ [loading]: false, adicionales_categoria: res })
        })
        .catch(error => {
            this.setState({ [loading]: false })
            toast.error("Error al consultar la información, intente nuevamente", this.state.toaststyle)
        })
    }

    getAdicionales(id){
        const loading = 'loadingAdicionales'
        this.setState({ [loading]: true })
        return fetch(`${data.urlapi}/api/adicionales/list-adicionales?idestablecimiento=${id}`)
        .then(res => res.json())
        .then(res => {
            if(!res){
                toast.error('No se obtuvieron datos del servidor')
                return this.setState({ [loading]: false })
            }
            if(res.errorMessage){
                toast.error(res.errorMessage)
                return this.setState({ [loading]: false })
            }
            this.setState({ [loading]: false, adicionales: res })
        })
        .catch(error => {
            this.setState({ [loading]: false })
            toast.error("Error al consultar la información, intente nuevamente", this.state.toaststyle)
        })
    }

    handleChangeEstablecimiento(e){
        const { value } = e.target
        this.getAdicionales(value)
        return this.setState({ establecimiento_seleccionado: value })
    }

    async getEstablecimientos(id_user){
        this.setState({ loadingEstablecimientos: true })
        return fetch(`${data.urlapi}/api/establecimientos/list?id_user=${id_user}`)
        .then(res => res.json())
        .then(async res => {
            if(res.length > 0){
                this.setState({ establecimiento_seleccionado: res[0]._id })
                await this.getAdicionales(res[0]._id)
                await this.getAdicionalesCategoria(res[0]._id)
            }
            this.setState({ loadingEstablecimientos: false, establecimientos: res, loadingAdicionales: false })
        })
        .catch(error => {
            this.setState({ loadingEstablecimientos: false })
            toast.error("Error al consultar la información, intente nuevamente", this.state.toaststyle)
        })
    }
    
    showByTipoVisualizacion(){
        const { tipo_visualizacion, pedidos } = this.state
        if(tipo_visualizacion==='pendientes') return this.visualizarPedidos(pedidos.filter(pe=>pe.status===0),'pendientes')
        if(tipo_visualizacion==='en-preparacion') return this.visualizarPedidos(pedidos.filter(pe=>pe.status===1), 'en-preparacion')
        if(tipo_visualizacion==='listos-para-recoleccion') return this.visualizarPedidos(pedidos.filter(pe=>pe.status===2), 'listos-para-recoleccion')
        if(tipo_visualizacion==='historial') return this.visualizarPedidos(pedidos.filter(pe=>pe.status!==0&&pe.status!==1&&pe.status!==2 ), 'historial')
    }

    handleChangeSelectOrigenRegion(e){
        return this.setState({ idregiondestino: e.value })
    }

    handleChangeSelectOrigen(e){
        let opciones = []
        if(e){
            e.map(valor => {
                return opciones.push(valor.value)
            })
        }
        return this.setState({ idcomunadestino: opciones })
    }

    formularioCreacionTarifa(){
        const { loadingEsquemaPais, regiones, creandoAdicionalCategoria, adicionales_categoria, comunas_descartar_creacion, creandoAdicional, comunas, establecimientos, idregiondestino } = this.state

        if(loadingEsquemaPais){
            return <div>
                <Spinner animation="grow" />
                <h3>Cargando información</h3>
            </div>
        }

        let optionscomunas = [
            { value:'', label:'Seleccione'}
        ]

        const optionsregiones = [
            { value:'', label:'Todos'}
        ]

        regiones.map(region => {
            return optionsregiones.push({
                value: region._id,
                label: region.nombre
            })
        })

        if(idregiondestino !== false){
            const agregar_comunas = comunas.filter(com => com.idregion === idregiondestino )
            agregar_comunas.map(comuna => {
                if(comunas_descartar_creacion.includes(comuna._id) !== false) return false
                return optionscomunas.push({ value: comuna._id, label: comuna.nombre })
            })
        }

        return <div>
            <Card className="mb-3">
        <Card.Body>
          <Row>
            <Col xs={12}>
                <h3>Crear nuevo adicional</h3>
                <p>Puedes crear uno o varios adicionales que puede permitir agregar a un pedido</p>
            </Col>

            <Col md={2} className="mb-3">
            <label className='form-control-label'>Establecimiento</label>
            <select className='form-control' name="id_categoria" onChange={this.handleChangeEstablecimiento}>
                {
                    establecimientos.map((est,i) => {
                        return <option key={`op-${i}`} value={est._id}>{est.titulo}</option>
                    })
                }
            </select>
            </Col> 
  
            <Col md={2} className="mb-3">
            <label className='form-control-label'>Título</label>
            <input className='form-control' name="titulo" onChange={this.handleChangeAdicional} />
            </Col>  
            <Col md={2} className="mb-3">
            <label className='form-control-label'>SKU</label>
            <input className='form-control' name="sku" onChange={this.handleChangeAdicional} />
            </Col>  

            

            <Col md={2} className="mb-3">
            <label className='form-control-label'>Disponible</label>
            <select className='form-control' name="disponible" onChange={this.handleChangeAdicional}>
                <option value="">Selecciona</option>
                <option value="disponible">Disponible</option>
                <option value="no-disponible">No Disponible</option>
            </select>
            </Col>  

            <Col md={2} className="mb-3">
            <label className='form-control-label'>Categoría</label>
            <select className='form-control' name="id_categoria" onChange={this.handleChangeAdicional}>
                <option value="">Selecciona</option>
                {
                    adicionales_categoria.map(cat => {
                        return <option value={cat._id}>{cat.titulo}</option>
                    })
                }
            </select>
            </Col>  

            <Col md={2} className="mb-3">
            <label className='form-control-label'>Precio</label>
            <input type="number" className='form-control' name="precio" onChange={this.handleChangeAdicional} />
            </Col>  

            <Col md={3} className="mb-3">
            <label className='form-control-label d-block'>Click para crear</label>
            { creandoAdicional === true ? <Spinner animation="grow" /> : <Button  variant="success" onClick={()=>this.crearAdicional()} >CREAR ADICIONAL</Button> }
            </Col>      
          </Row>          

          

        </Card.Body>
      </Card>

      <Card className="mb-3">
          <Card.Body>
              <Row>
                <Col xs={12}>
                <h3>Crear nueva categoría de adicionales</h3>
                </Col>
                <Col md={3}>
                    <label className='form-control-label'>Título</label>
                    <input className='form-control' name="titulo" onChange={this.handleChangeAdicionalCategoria} />
                </Col>

                <Col md={3} className="mb-3">
                <label className='form-control-label d-block'>Click para crear</label>
                { creandoAdicionalCategoria === true ? <Spinner animation="grow" /> : <Button  variant="success" onClick={()=>this.crearAdicionalCategoria()} >CREAR CATEGORÍA</Button> }
                </Col>  

              </Row>
          </Card.Body>
      </Card>
        </div>
    }

    eliminarAdicional(id){
        const { adicionales } = this.state
        this.setState({ eliminando: true })
        return fetch(`${data.urlapi}/api/adicionales/delete-adicional?id=${id}`)
        .then(res => res.json())
        .then(res => {
            const i = adicionales.findIndex(li => li._id === id)
            if(i > -1){
                adicionales.splice(i,1)
                toast.success('Eliminada exitosamente', this.state.toaststyle)
                this.setState({ adicionales })
            }
            this.setState({ eliminando: false })
        })
        .catch(error => {
            console.log(error)
            this.setState({ eliminando: false })
            toast.error("Error al consultar la información, intente nuevamente", this.state.toaststyle)
        })

    }

    solicitarEliminarAdicional(id){
        return confirmAlert({
            title: '¿Estás seguro?',
            message: `¿Deseas eliminar este item? Esta opción no se puede deshacer`,
            buttons: [
              {
                label: 'CONFIRMAR',
                onClick: () => this.eliminarAdicional(id)
              },
              {
                label: 'CANCELAR',
                onClick: () => false
              },
            ],
          })
    }

    sinRegistros(){
        return <div className='p-5'>
            <Row className="justify-content-md-center">
                <Col md={6} className="text-center">
                <h4>Aún no tienes adicionales</h4>
                <p>Puedes crear productos adicionales que los usuarios pueden agregar a otros productos</p>
                </Col>
            </Row>
        </div>
    }

    mostrarAdicionales(){
        const { adicionales, loadingAdicionales, eliminando, actualizando, adicionales_categoria } = this.state
        if(loadingAdicionales) return <Spinner animation='grow' />
        if(adicionales.length < 1) return this.sinRegistros()
        return <div>

            <h3>{adicionales.length} Adicionales encontrados</h3>
            {
                adicionales.map(adicional => {
                    return <div key={`adicional-${adicional._id}`} className="mb-3" >
                        <Card >
                            <Card.Body>
                                <Row>
                                    <Col md={2}>
                                        <label className='form-control-label d-block mb-0'>Título</label>
                                        <input className="form-control" name="titulo" id_cat={adicional._id} value={adicional.titulo} onChange={this.handleChangeAdicionalEditar} />
                                    </Col>
                                    <Col md={2}>
                                        <label className='form-control-label d-block mb-0'>SKU</label>
                                        <input className="form-control" name="sku" id_cat={adicional._id} value={adicional.sku} onChange={this.handleChangeAdicionalEditar} />
                                    </Col>
                                    <Col md={2}>
                                        <label className='form-control-label d-block mb-0'>Disponibilidad</label>
                                        <select className='form-control' name="disponible" id_cat={adicional._id} onChange={this.handleChangeAdicionalEditar} value={adicional.disponible} >
                                            <option value="">Selecciona</option>
                                            <option value="disponible">Disponible</option>
                                            <option value="no-disponible">No Disponible</option>
                                        </select>
                                    </Col>
                                    <Col md={2}>
                                    <label className='form-control-label d-block mb-0'>Categoría</label>
                                    <select className='form-control' name="id_categoria" id_cat={adicional._id} value={adicional.id_categoria} onChange={this.handleChangeAdicionalEditar} >
                                        <option value="">Selecciona</option>
                                        {
                                            adicionales_categoria.map(cat => {
                                                return <option value={cat._id}>{cat.titulo}</option>
                                            })
                                        }
                                    </select>
                                    </Col> 
                                    <Col md={2}>
                                        <label className='form-control-label d-block mb-0'>Precio</label>
                                        <input type="number" className="form-control" name="precio" id_cat={adicional._id} value={adicional.precio} onChange={this.handleChangeAdicionalEditar} />
                                    </Col>
                                    <Col md={1}>
                                        <label className='form-control-label d-block mb-0'>Click para eliminar</label>
                                        { eliminando ? <Spinner animation='grow' /> : <Button size="sm" variant='danger' onClick={()=>this.solicitarEliminarAdicional(adicional._id)}>ELIMINAR</Button>}
                                    </Col>
                                    <Col md={1}>
                                        <label className='form-control-label d-block mb-0'>Click para guardar</label>
                                        { actualizando ? <Spinner animation='grow' /> : <Button size="sm" variant='success' onClick={()=>this.guardarCambiosAdicional(adicional._id)}>GUARDAR</Button>}
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                        </div>
                })
            }
        </div>
    }

    mostrarDatosListas(datos, filtro, input){
        const { eliminando } = this.state
        const lowercasedFilter = filtro.toLowerCase();
        const listasFiltradas = datos.filter(item => {
            return Object.keys(item).some(key =>
                item[key].toString().toLowerCase().includes(lowercasedFilter)
            );
        });

        return <div>
<h4>{datos.length} Planes</h4>
<p>Elige los planes que desees</p>

{filtro !== ''  ? <Col xs={12}><label className="form-control-label">{listasFiltradas.length} Coincicencias</label></Col>:false}
<Row>
{ listasFiltradas.map((lista,i)=>{
        return <Col md={3} key={`user${i}`}>
            <Card >
            <Card.Img variant="top" src={lista.image} />
        <Card.Body>
            <h1 className="mb-0">{lista.title}</h1>
            <p className="mb-0">{lista.description}</p>
            <h3 >CLP ${Intl.NumberFormat("es-ES").format(lista.price)} <p className="mb-0" style={{ fontSize: 13, display: 'inline-block' }}>{lista.frecuency}</p></h3> 
            { eliminando ? <Spinner animation="border" /> : this.botonSeleccionar(lista._id) }
            <hr />
            <h6 className="mb-0">Características</h6>
            </Card.Body>
            <ListGroup variant="flush">
                {
                    lista.beneficios.map(ben => {
                        return <ListGroup.Item><span className="mb-0" style={{ fontSize: 12 }}>{ben.title}</span></ListGroup.Item>
                    })
                }
            </ListGroup>
        </Card>
        </Col>
    }) }
    </Row>
        </div>
    }

    botonSeleccionar(id){
        const { planes_activos } = this.state
        const i = planes_activos.findIndex(plan => plan._id === id)
        if(i > -1) return <Button size="sm" variant="outline-secondary" >PLAN ACTIVO</Button>

        return <Button size="sm" variant="outline-primary" ><Link to={`pay-plan-${id}`} >SELECCIONAR</Link></Button>
    }
    
    render(){
        const { id_establecimiento } = this.state
        return(
            <div className="fluid">
       <Header/>
       <div className="container-fluid">
  <div className="row">
    <main className="col-md-12 ms-sm-auto col-lg-12 px-md-4 leftauto">
                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <Link to={`/establecimiento-${id_establecimiento}`} className='text-dark' ><i className="fas fa-chevron-left"></i> Volver</Link>
                </div>
                <h1 className="h2"> Adicionales para productos </h1>
                {this.formularioCreacionTarifa()}
                <Card >
                    <Card.Body>
                    {this.mostrarAdicionales()}
                    </Card.Body>
                </Card>
                </main>
            </div>
            </div>
        </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        miusuario: state.miusuario
    }
}

export default connect(mapStateToProps)(Adicionales);