import React, { Component} from 'react'
import { connect } from 'react-redux'
import { Row, Col, Card, Button } from 'react-bootstrap'
import Header from '../Header'
import Spinner from 'react-bootstrap/Spinner'
import { toast } from 'react-toastify';
import 'moment/locale/es';
import data from '../../lib/backend/data'
import { ListGroup } from 'react-bootstrap'
import { Link } from 'react-router-dom'

class EstablecimientoCrear extends Component {
    constructor(props){
        super(props)
        this.state = {
            user: JSON.parse(localStorage.getItem('bumer_bknd_user')),
            creando: false,
            establecimiento: {}

        }
        this.handleChange = this.handleChange.bind(this)
    }

    handleChange(e){
        const { name, value } = e.target
        const { establecimiento } = this.state
        establecimiento[name] = value
        return this.setState({ establecimiento })
    }

    getEstablecimientos(id_user){
        this.setState({ loadingEstablecimientos: true })
        return fetch(`${data.urlapi}/api/establecimientos/list?id_user=${id_user}`)
        .then(res => res.json())
        .then(res => {
            this.setState({ loadingEstablecimientos: false, listas: res, primer_registro: res.length > 0 ? false : true })
        })
        .catch(error => {
            this.setState({ loadingEstablecimientos: false })
            toast.error("Error al consultar la información, intente nuevamente")
        })
    }

    crearEstablecimiento(){
        const { establecimiento, user } = this.state
        establecimiento.id_user = user._id
        establecimiento.status = 1
        establecimiento.active = 1

        const requeridos = [ 'titulo' ]
        let faltantes = []
        requeridos.map(campo => {
            if(!establecimiento[campo]) faltantes.push(campo)
            return false
        })
        if(faltantes.length > 0){
            console.log(faltantes)
            return toast.error(`Faltan los campos: ${faltantes.join(', ')}`)
        }

        this.setState({ creando: true })
        return fetch(`${data.urlapi}/api/establecimientos/new`,{
            method: 'POST',
            body: JSON.stringify(establecimiento),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(res => res.json())
        .then(res => {
            setTimeout(() => {
                return window.location = '/establecimientos'
            }, 1000);
            return toast.success('Creado exitosamente, espere mientras redirigimos')
        })
        .catch(error => {
            this.setState({ creando: false })
            toast.error("Error al consultar la información, intente nuevamente")
        })

    }

    requiredIcon(){
        return <span style={{ fontSize: 15 }} className='text-danger' >*</span>
    }

    formularioCreacion(){
        const { creando } = this.state

        return <div>

            <Row>

            <Col md={12} >
                <h4><i className="fas fa-store-alt"></i> Detalles</h4>
            </Col>

            <Col md={3} >
                <label className='form-control-label' >Nombre fantasía {this.requiredIcon()}</label>
                <input className='form-control' name="titulo" onChange={this.handleChange} />
            </Col>
            
            <Col md={3} >
                <label className='form-control-label' >Teléfono</label>
                <input className='form-control' name="telefono" onChange={this.handleChange} />
            </Col>

            <Col md={6} >
                <label className='form-control-label' >Dirección</label>
                <input className='form-control' name="direccion" onChange={this.handleChange} />
            </Col>

            <Col md={12} className='mt-3' >
                <h4><i className="far fa-thumbs-up"></i> Redes sociales</h4>
            </Col>

            <Col md={3} >
                <label className='form-control-label' >Facebook</label>
                <input className='form-control' name="facebook" onChange={this.handleChange} />
            </Col>

            <Col md={3} >
                <label className='form-control-label' >Instagram</label>
                <input className='form-control' name="instagram" onChange={this.handleChange} />
            </Col>

            <Col md={3} >
                <label className='form-control-label' >Twitter</label>
                <input className='form-control' name="twitter" onChange={this.handleChange} />
            </Col>

            <Col md={12} className='mt-3' >
                <h4><i className="far fa-images"></i> Imágenes</h4>
            </Col>

            <Col md={6}>
                <label className='form-control-label' >Logotipo</label>
                <input type="file" className='form-control' name="logo" onChange={this.handleChangeLogotipo} />
            </Col>

            <Col md={6}>
                <label className='form-control-label' >Imagen Encabezado ( 1920 x 768 Recomendado)</label>
                <input type="file" className='form-control' name="logo" onChange={this.handleChangeLogotipo} />
            </Col>

            <Col md={12} className='mt-3' >
                { creando ? <div><Spinner animation='grow' /><h3>Cargando</h3></div> : <Button variant="success" onClick={()=>this.crearEstablecimiento()} >CREAR SUCURSAL</Button>}
            </Col>

            </Row>

        </div>
    }

    mostrarDatos(){
        const { loadingEstablecimientos, establecimientos, eliminando, filtro } = this.state


        const lowercasedFilter = filtro.toLowerCase();
        const listasFiltradas = establecimientos.filter(item => {
            return Object.keys(item).some(key =>
                item[key].toString().toLowerCase().includes(lowercasedFilter)
            )
        })

        if(loadingEstablecimientos){
            return <div>
                <Spinner animation='grow' />
                <h3>Cargando información</h3>
            </div>
        }

        return <Card style={{ width: '100%' }}>
        <Card.Body>
          <Row>

            <Col xs={12}>
        <div>
<h4>{establecimientos.length} Registros</h4>
<p></p>

{filtro !== ''  ? <Col xs={12}><label className="form-control-label">{listasFiltradas.length} Coincicencias</label></Col>:false}
<Row>
{ listasFiltradas.map((lista,i)=>{
        return <Col md={3} key={`user${i}`}>
            <Card >
            <Card.Img variant="top" src={lista.image} />
        <Card.Body>
            <h1 className="mb-0">{lista.title}</h1>
            <p className="mb-0">{lista.description}</p>
            <h3 >CLP ${Intl.NumberFormat("es-ES").format(lista.price)} <p className="mb-0" style={{ fontSize: 13, display: 'inline-block' }}>{lista.frecuency}</p></h3> 
            { eliminando ? <Spinner animation='grow' /> : this.botonSeleccionar(lista._id) }
            <hr />
            <h6 className="mb-0">Características</h6>
            </Card.Body>
            <ListGroup variant="flush">
                {
                    lista.beneficios.map(ben => {
                        return <ListGroup.Item><span className="mb-0" style={{ fontSize: 12 }}>{ben.title}</span></ListGroup.Item>
                    })
                }
            </ListGroup>
        </Card>
        </Col>
    }) }
    </Row>
        </div>
            </Col>

          
          </Row>          

          

        </Card.Body>
      </Card>
    }

    
    render(){
        return(
            <div className="fluid">
       <Header/>  
       <div className="container-fluid">
  <div className="row">
    <main className="col-md-12 ms-sm-auto col-lg-12 px-md-4 leftauto">
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
      <Link to="/establecimientos" className='text-dark' ><i className="fas fa-chevron-left"></i> Volver a sucursales</Link>
      </div>
      <h1 className="h2"> Crear sucursal </h1>
      {this.formularioCreacion()}
    </main>
  </div>
</div>
    </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        miusuario: state.miusuario
    }
}

export default connect(mapStateToProps)(EstablecimientoCrear);