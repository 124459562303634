import React, { Component} from 'react'
import { connect } from 'react-redux'
import { Row, Col, Card, Button } from 'react-bootstrap'
import Header from '../Header'
import Spinner from 'react-bootstrap/Spinner'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import 'moment/locale/es';
import data from '../../lib/backend/data'
import { ListGroup } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import 'moment/locale/es';
import Select from 'react-select';


class Shipping extends Component {
    constructor(props){
        super(props)
        this.state = {
            user: JSON.parse(localStorage.getItem('bumer_bknd_user')),
            establecimientos: [],
            establecimiento_seleccionado: false,
            regiones: [],
            comunas: [],
            idregiondestino: false,
            idcomunadestino: false,
            nueva_tarifa: {
                precio: 0
            },
            creandoTarifa: false,
            tarifas: [],
            loadingTarifas: true,
            comunas_descartar_creacion: []
        }

        this.handleChange = this.handleChange.bind(this)
        this.handleChangeTarifa = this.handleChangeTarifa.bind(this)
        this.handleChangeSelectOrigenRegion = this.handleChangeSelectOrigenRegion.bind(this)
        this.handleChangeSelectOrigen = this.handleChangeSelectOrigen.bind(this)
        this.handleChangeEstablecimiento = this.handleChangeEstablecimiento.bind(this)

    }

    handleChange(e){
        const { name, value } = e.target
        return this.setState({ [name]: value })
    }

    handleChangeTarifa(e){
        const { nueva_tarifa } = this.state
        const { name, value } = e.target
        nueva_tarifa[name] = value
        return this.setState({ nueva_tarifa })
    }

    componentDidMount(){
        const { user } = this.state
        this.getEstablecimientos(user._id.toString())
        this.getEsquemaPais(user.countrycode)
    }

    handleClose(){
        return this.setState({ modal_pedido_seleccionado: false, tipo_visualizacion_pedido: 'resumen' })
    }

    getEsquemaPais(countrycode){
        const loading = 'loadingEsquemaPais'
        this.setState({ [loading]: true })
        return fetch(`${data.urlapi}/api/tarifas/esquema-pais?countrycode=${countrycode}`,{
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(res => res.json())
        .then(res => {
            console.log({res,countrycode})
            if(!res){
                toast.error('No se obtuvieron datos del servidor')
                return this.setState({ [loading]: false })
            }
            if(res.errorMessage){
                toast.error(res.errorMessage)
                return this.setState({ [loading]: false })
            }
            this.setState({ [loading]: false, pais: res.pais, regiones: res.regiones, comunas: res.comunas })
        })
        .catch(error => {
            toast.error("Error al realizar esta operación, intente nuevamente", this.state.toaststyle)
            this.setState({ [loading]: false })
        })
    }

    eliminarTarifa(){
        const { nueva_tarifa, establecimiento_seleccionado, pais, idregiondestino, idcomunadestino } = this.state
        nueva_tarifa.id_establecimiento = establecimiento_seleccionado
        nueva_tarifa.destino = {
            idpais: pais._id,
            idregion: idregiondestino,
            idcomuna: idcomunadestino
        }
        const loading = 'loadingEsquemaPais'
        this.setState({ [loading]: true })
        return fetch(`${data.urlapi}/api/tarifas`,{
            method:'POST',
            body: JSON.stringify( nueva_tarifa ),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(res => res.json())
        .then(res => {
            if(res._id){
                toast.success('Realizado exitosamente')
            }
            this.setState({ loading_accion_pedido: false })
        })
        .catch(error => {
            this.setState({ loading_accion_pedido: false })
            toast.error("Error al realizar esta operación, intente nuevamente", this.state.toaststyle)
        })
    }
    crearTarifa(){
        const { nueva_tarifa, establecimiento_seleccionado, pais, idregiondestino, idcomunadestino } = this.state
        nueva_tarifa.id_establecimiento = establecimiento_seleccionado
        nueva_tarifa.destino = {
            idpais: pais._id,
            idregion: idregiondestino,
            idcomuna: idcomunadestino
        }
        const loading = 'creandoTarifa'
        this.setState({ [loading]: true })
        return fetch(`${data.urlapi}/api/tarifas`,{
            method:'POST',
            body: JSON.stringify( nueva_tarifa ),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(res => res.json())
        .then(res => {
            if(res._id){ 
                toast.success('Guardado exitosamente')
            } else {
                toast.error('Ocurrió un erro inesperado')
            }
            this.setState({ [loading]: false })
        })
        .catch(error => {
            this.setState({ [loading]: false })
            toast.error("Error al realizar esta operación, intente nuevamente", this.state.toaststyle)
        })
    }

    getTarifas(id){
        const loading = 'loadingTarifas'
        this.setState({ [loading]: true })
        return fetch(`${data.urlapi}/api/tarifas/list?idestablecimiento=${id}`)
        .then(res => res.json())
        .then(res => {
            if(!res){
                toast.error('No se obtuvieron datos del servidor')
                return this.setState({ [loading]: false })
            }
            if(res.errorMessage){
                toast.error(res.errorMessage)
                return this.setState({ [loading]: false })
            }
            if(res.length > 0){
                const comunas_descartar_creacion = []
                res.map(tarifa => {
                    if(!tarifa.destino) return false
                    if(typeof tarifa.destino !== 'object') return false
                    if(!tarifa.destino.idcomuna) return false
                    if(Array.isArray(tarifa.destino.idcomuna) !== true) return false
                    tarifa.destino.idcomuna.map(id => comunas_descartar_creacion.push(id))
                    return true
                })
                console.log(comunas_descartar_creacion)
                this.setState({ comunas_descartar_creacion })
            }
            this.setState({ [loading]: false, tarifas: res })
        })
        .catch(error => {
            this.setState({ [loading]: false })
            toast.error("Error al consultar la información, intente nuevamente", this.state.toaststyle)
        })
    }

    handleChangeEstablecimiento(e){
        const { value } = e.target
        this.getTarifas(value)
        return this.setState({ establecimiento_seleccionado: value })
    }

    async getEstablecimientos(id_user){
        this.setState({ loadingEstablecimientos: true })
        return fetch(`${data.urlapi}/api/establecimientos/list?id_user=${id_user}`)
        .then(res => res.json())
        .then(async res => {
            if(res.length > 0){
                this.setState({ establecimiento_seleccionado: res[0]._id })
                await this.getTarifas(res[0]._id)
            }
            this.setState({ loadingEstablecimientos: false, establecimientos: res, loadingTarifas: false })
        })
        .catch(error => {
            this.setState({ loadingEstablecimientos: false })
            toast.error("Error al consultar la información, intente nuevamente", this.state.toaststyle)
        })
    }
    
    showByTipoVisualizacion(){
        const { tipo_visualizacion, pedidos } = this.state
        if(tipo_visualizacion==='pendientes') return this.visualizarPedidos(pedidos.filter(pe=>pe.status===0),'pendientes')
        if(tipo_visualizacion==='en-preparacion') return this.visualizarPedidos(pedidos.filter(pe=>pe.status===1), 'en-preparacion')
        if(tipo_visualizacion==='listos-para-recoleccion') return this.visualizarPedidos(pedidos.filter(pe=>pe.status===2), 'listos-para-recoleccion')
        if(tipo_visualizacion==='historial') return this.visualizarPedidos(pedidos.filter(pe=>pe.status!==0&&pe.status!==1&&pe.status!==2 ), 'historial')
    }

    handleChangeSelectOrigenRegion(e){
        return this.setState({ idregiondestino: e.value })
    }

    handleChangeSelectOrigen(e){
        let opciones = []
        if(e){
            e.map(valor => {
                return opciones.push(valor.value)
            })
        }
        return this.setState({ idcomunadestino: opciones })
    }

    formularioCreacionTarifa(){
        const { loadingEsquemaPais, regiones, comunas_descartar_creacion, creandoTarifa, comunas, establecimientos, idregiondestino } = this.state

        if(loadingEsquemaPais){
            return <div>
                <Spinner animation='grow' />
                <h3>Cargando información</h3>
            </div>
        }

        let optionscomunas = [
            { value:'', label:'Seleccione'}
        ]

        const optionsregiones = [
            { value:'', label:'Todos'}
        ]

        regiones.map(region => {
            return optionsregiones.push({
                value: region._id,
                label: region.nombre
            })
        })

        if(idregiondestino !== false){
            const agregar_comunas = comunas.filter(com => com.idregion === idregiondestino )
            agregar_comunas.map(comuna => {
                if(comunas_descartar_creacion.includes(comuna._id) !== false) return false
                return optionscomunas.push({ value: comuna._id, label: comuna.nombre })
            })
        }

        return <Card style={{ width: '100%' }} className="mb-3">
        <Card.Body>
        

          <Row>

            <Col xs={12}>
                <h3>Crear nueva tarifa de despacho</h3>
                <p>Puedes crear una tarifa para una o varias comunas de tu ciudad</p>
            </Col>

            <Col md={12} className="mb-3">
            <label className='form-control-label'>Establecimiento</label>
            <select className='form-control' onChange={this.handleChangeEstablecimiento}>
                {
                    establecimientos.map((est,i) => {
                        return <option key={`op-${i}`} value={est._id}>{est.titulo}</option>
                    })
                }
            </select>
            </Col> 


            <Col md={3} className="mb-3">
            <label className="form-control-label">Región</label>
            <Select 
                style={{ marginBottom: 10 }}
                onChange={this.handleChangeSelectOrigenRegion}
                options={optionsregiones}
                defaultValue={''}
                placeholder="Buscar región..."
              />
            </Col>         
            <Col md={9} className="mb-3">
            <label className="form-control-label">Comuna</label>
            <Select 
                style={{ marginBottom: 10 }}
                onChange={this.handleChangeSelectOrigen}
                options={optionscomunas}
                isMulti={true}
                placeholder="Buscar comuna..."
              />
            </Col>   
            <Col md={3} className="mb-3">
            <label className='form-control-label'>Precio</label>
            <input type="number" className='form-control' name="precio" onChange={this.handleChangeTarifa} />
            </Col>      
            <Col md={3} className="mb-3">
            <label className='form-control-label d-block'>Click para crear</label>
            { creandoTarifa === true ? <Spinner animation='grow' /> : <Button  variant="success" onClick={()=>this.crearTarifa()} >CREAR TARIFA</Button> }
            </Col>      
          </Row>          

          

        </Card.Body>
      </Card>
    }

    eliminarLista(id){
        const { listas } = this.state
        this.setState({ eliminando: true })
        return fetch(`${data.urlapi}/api/listas/delete?id=${id}`)
        .then(res => res.json())
        .then(res => {
            const i = listas.findIndex(li => li._id === id)
            if(i > -1){
                listas.splice(i,1)
                toast.success('Eliminada exitosamente', this.state.toaststyle)
                this.setState({ listas })
            }
            this.setState({ eliminando: false })
        })
        .catch(error => {
            console.log(error)
            this.setState({ eliminando: false })
            toast.error("Error al consultar la información, intente nuevamente", this.state.toaststyle)
        })

    }

    sinRegistros(){
        return <div className='p-5'>
            <Row className="justify-content-md-center">
                <Col md={6} className="text-center">
                <h4>Aún no tienes tarifas</h4>
                <p>Puedes crear tarifas de acuerdo a las zonas que quieres cubrir con servicio delivery en tu ciudad</p>
                </Col>
            </Row>
        </div>
    }

    mostrarTarifas(){
        const { tarifas, loadingTarifas, comunas } = this.state
        if(loadingTarifas) return <Spinner animation='border' />
        if(tarifas.length < 1) return this.sinRegistros()
        return <div>

            <h3>{tarifas.length} Tarifas encontradas</h3>
            {
                tarifas.map((tarifa,i) => {

                    const comunas_encontradas = tarifa.destino.idcomuna.map(com => {
                        const i = comunas.findIndex(co => co._id === com)
                        if(i < 0) return 'No identificada'
                        return comunas[i].nombre
                    })
                    return <div key={`tarifa-${tarifa._id}`}>
                        <Card >
                            <div className="p-3">
                                <p className='mb-0'>{comunas_encontradas.join(', ')}</p>
                                <h3 className='mb-0'>CLP ${Intl.NumberFormat("es-ES").format(tarifa.precio)}</h3>
                            </div>
                        </Card>
                        </div>
                })
            }
        </div>
    }

    mostrarDatosListas(datos, filtro, input){
        const { eliminando } = this.state
        const lowercasedFilter = filtro.toLowerCase();
        const listasFiltradas = datos.filter(item => {
            return Object.keys(item).some(key =>
                item[key].toString().toLowerCase().includes(lowercasedFilter)
            );
        });

        return <div>
<h4>{datos.length} Planes</h4>
<p>Elige los planes que desees</p>

{filtro !== ''  ? <Col xs={12}><label className="form-control-label">{listasFiltradas.length} Coincicencias</label></Col>:false}
<Row>
{ listasFiltradas.map((lista,i)=>{
        return <Col md={3} key={`user${i}`}>
            <Card >
            <Card.Img variant="top" src={lista.image} />
        <Card.Body>
            <h1 className="mb-0">{lista.title}</h1>
            <p className="mb-0">{lista.description}</p>
            <h3 >CLP ${Intl.NumberFormat("es-ES").format(lista.price)} <p className="mb-0" style={{ fontSize: 13, display: 'inline-block' }}>{lista.frecuency}</p></h3> 
            { eliminando ? <Spinner animation='grow' /> : this.botonSeleccionar(lista._id) }
            <hr />
            <h6 className="mb-0">Características</h6>
            </Card.Body>
            <ListGroup variant="flush">
                {
                    lista.beneficios.map(ben => {
                        return <ListGroup.Item><span className="mb-0" style={{ fontSize: 12 }}>{ben.title}</span></ListGroup.Item>
                    })
                }
            </ListGroup>
        </Card>
        </Col>
    }) }
    </Row>
        </div>
    }

    botonSeleccionar(id){
        const { planes_activos } = this.state
        const i = planes_activos.findIndex(plan => plan._id === id)
        if(i > -1) return <Button size="sm" variant="outline-secondary" >PLAN ACTIVO</Button>

        return <Button size="sm" variant="outline-primary" ><Link to={`pay-plan-${id}`} >SELECCIONAR</Link></Button>
    }
    
    render(){
        return(
            <div className="fluid">
       <Header/>
       <div className="container-fluid">
  <div className="row">
    <main className="col-md-12 ms-sm-auto col-lg-12 px-md-4 leftauto">
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 ">
      </div>
    <h1 className="h2"> Tarifas delivery </h1>

      {this.formularioCreacionTarifa()}
      <Card >
          <Card.Body>
          {this.mostrarTarifas()}
          </Card.Body>
      </Card>
    </main>
  </div>
</div>
    </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        miusuario: state.miusuario
    }
}

export default connect(mapStateToProps)(Shipping);