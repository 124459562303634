import React, { Component} from 'react'
import { connect } from 'react-redux'
import { Row, Col, Card, Button } from 'react-bootstrap'
import Header from '../Header'
import Spinner from 'react-bootstrap/Spinner'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import 'moment/locale/es';
import data from '../../lib/backend/data'
import { Link } from 'react-router-dom'
import 'moment/locale/es';

class Payments extends Component {
    constructor(props){
        super(props)
        this.state = {
            user: JSON.parse(localStorage.getItem('bumer_bknd_user')),
            id_establecimiento: this.props.match.params.id,
            establecimientos: [],
            establecimiento_seleccionado: false,
            habilitandoPago: false,
            tarifas: [],
            loadingMediosPagoActivos: true,
            tipos_pago: [],
            tipo_pago_seleccionado: '',
            tipos_pago_activos: []
        }

        this.handleChange = this.handleChange.bind(this)
        this.handleChangeTarifa = this.handleChangeTarifa.bind(this)
        this.handleChangeTipoPago = this.handleChangeTipoPago.bind(this)

    }

    handleChange(e){
        const { name, value } = e.target
        return this.setState({ [name]: value })
    }

    handleChangeTarifa(e){
        const { nueva_tarifa } = this.state
        const { name, value } = e.target
        nueva_tarifa[name] = value
        return this.setState({ nueva_tarifa })
    }

    componentDidMount(){
        const { id_establecimiento } = this.state
        this.getMediosPagoActivos(id_establecimiento)
        this.getMediosPago()
    }

    getMediosPago(){
        const loading = 'loadingEsquemaPais'
        this.setState({ [loading]: true })
        return fetch(`${data.urlapi}/api/mediospago/list`,{
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(res => res.json())
        .then(res => {
            if(!res){
                toast.error('No se obtuvieron datos del servidor')
                return this.setState({ [loading]: false })
            }
            if(res.errorMessage){
                toast.error(res.errorMessage)
                return this.setState({ [loading]: false })
            }
            this.setState({ [loading]: false, tipos_pago: res })
        })
        .catch(error => {
            toast.error("Error al realizar esta operación, intente nuevamente", this.state.toaststyle)
            this.setState({ [loading]: false })
        })
    }

    deshabilitarTipoPago(id){
        const { tipos_pago_activos } = this.state
        const loading = 'eliminando'
        this.setState({ [loading]: true })
        return fetch(`${data.urlapi}/api/mediospago?id=${id}`,{
            method:'DELETE',
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(res => res.json())
        .then(res => {
            if(res.errorMessage){
                toast.error(res.errorMessage)
                return this.setState({ [loading]: false })
            }
            const i = tipos_pago_activos.findIndex(tipo => tipo._id === id)
            tipos_pago_activos.splice(i,1)
            toast.success('Desactivado exitosamente')
            this.setState({ tipos_pago_activos, [loading]: false })
        })
        .catch(error => {
            this.setState({ [loading]: false })
            toast.error("Error al realizar esta operación, intente nuevamente", this.state.toaststyle)
        })
    }
    habilitarTipoPago(){
        const { tipo_pago_seleccionado, id_establecimiento, tipos_pago_activos } = this.state
        if(!tipo_pago_seleccionado) return toast.error('El tipo de pago es requerido')
        const loading = 'habilitandoPago'
        this.setState({ [loading]: true })
        return fetch(`${data.urlapi}/api/mediospago`,{
            method:'POST',
            body: JSON.stringify( {
                id_establecimiento,
                tipo_pago_seleccionado
            } ),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(res => res.json())
        .then(res => {
            console.log(res)
            if(res._id){ 
                tipos_pago_activos.unshift(res)
                toast.success('Guardado exitosamente')
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return this.setState({ [loading]: false })
            } else {
                toast.error('Ocurrió un error inesperado')
            }
            this.setState({ [loading]: false, tipos_pago_activos })
        })
        .catch(error => {
            this.setState({ [loading]: false })
            toast.error("Error al realizar esta operación, intente nuevamente", this.state.toaststyle)
        })
    }

    handleChangeTipoPago(e){
        const { value } = e.target
        return this.setState({ tipo_pago_seleccionado: value })
    }

    async getMediosPagoActivos(id_establecimiento){
        const loading = 'loadingMediosPagoActivos'
        this.setState({ [loading]: true })
        return fetch(`${data.urlapi}/api/mediospago/activos?idestablecimiento=${id_establecimiento}`)
        .then(res => res.json())
        .then(async res => {
            this.setState({ tipos_pago_activos: res, [loading]: false })
        })
        .catch(error => {
            this.setState({ [loading]: false })
            toast.error("Error al consultar la información, intente nuevamente", this.state.toaststyle)
        })
    }

    formularioCreacionTarifa(){
        const { loadingEsquemaPais, tipos_pago, habilitandoPago } = this.state

        if(loadingEsquemaPais){
            return <div>
                <Spinner animation='grow' />
                <h3>Cargando información</h3>
            </div>
        }

        return <Card style={{ width: '100%' }} className="mb-3">
        <Card.Body>
          <Row>
            <Col xs={12}>
                <h3>Habilitar método de pago</h3>
                <p>Selecciona el método de pago que deseas habilitar y has click en <b>habilitar</b></p>
            </Col>
            <Col md={4} className="mb-3">
            <label className='form-control-label'>Medio de pago</label>
            <select className='form-control' onChange={this.handleChangeTipoPago}>
                <option value="">Selecciona</option>
                {
                    tipos_pago.map((tipo,i) => {
                        return <option key={`tipopago-${i}`} value={tipo._id}>{tipo.title}</option>
                    })
                }
            </select>
            </Col> 
            <Col md={3} className="mb-3">
            <label className='form-control-label d-block'>Click para crear</label>
            { habilitandoPago === true ? <Spinner animation='grow' /> : <Button  variant="success" onClick={()=>this.habilitarTipoPago()} >HABILITAR</Button> }
            </Col>      
          </Row>          
        </Card.Body>
      </Card>
    }

    sinRegistros(){
        return <div className='p-5'>
            <Row className="justify-content-md-center">
                <Col md={6} className="text-center">
                <h4>Aún no tienes medios de pago habilitados</h4>
                <p>Sin medios de pago no podrás vender desde tu plataforma, habilita uno ahora.</p>
                </Col>
            </Row>
        </div>
    }

    mostrarTarifas(){
        const { tipos_pago_activos, loadingMediosPagoActivos, eliminando } = this.state
        if(loadingMediosPagoActivos) return <Spinner animation='border' />
        if(tipos_pago_activos.length < 1) return this.sinRegistros()
        return <div>

            <h3>{tipos_pago_activos.length} Medios de pago activos</h3>
            {
                tipos_pago_activos.map((tipopago,i) => {
                    return <div key={`tipopago-${tipopago._id}`}>
                        <Card className='mb-3'>
                            <div className="p-3">
                                <Row>
                                    <Col md={6}>
                                        <h3 className='mb-0'>{tipopago.title}</h3>
                                    </Col>
                                    <Col md={6} className="text-right">
                                        { eliminando ? <Spinner animation='border' /> : <Button size="sm" variant="outline-danger" onClick={()=>this.deshabilitarTipoPago(tipopago._id)} >DESACTIVAR</Button>}
                                    </Col>
                                </Row>
                            </div>
                        </Card>
                        </div>
                })
            }
        </div>
    }

    render(){
        const { id_establecimiento } = this.state
        return(
            <div className="fluid">
       <Header/>
       <div className="container-fluid">
  <div className="row">
    <main className="col-md-12 ms-sm-auto col-lg-12 px-md-4 leftauto">
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
      <Link to={`/establecimiento-${id_establecimiento}`} className='text-dark' ><i className="fas fa-chevron-left"></i> Volver</Link>
      </div>
    <h1 className="h2"> Medios de pago </h1>

      {this.formularioCreacionTarifa()}
      <Card >
          <Card.Body>
          {this.mostrarTarifas()}
          </Card.Body>
      </Card>
    </main>
  </div>
</div>
    </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        miusuario: state.miusuario
    }
}

export default connect(mapStateToProps)(Payments);