import { Col, Row } from "react-bootstrap"

const BoxProducto = (props) => {
    const {
        size
    } = props
    const pro = props.producto ? props.producto : false

    if(!pro) return false

    const mostrarOpcionEscogida = (producto) => {
        if(producto.opciones){
            if(Array.isArray(producto.opciones)){
                if(producto.opciones.length > 0){
                    const encontrar_seleccion = producto.opciones.findIndex(op => op.seleccionado === true)
                    if(encontrar_seleccion > -1){
                        return <p className='mb-0'>{producto.opciones[encontrar_seleccion].titulo}</p>
                    }
                }
            }
        }
        return false
    }

    const visualizarAdicionales = (producto) => {
        let escogidos = []
        if(producto.adicionales){
            if(Array.isArray(producto.adicionales)){
                if(producto.adicionales.length > 0){
                    const encontrar_adicionales_seleccionados = producto.adicionales.filter(op => {
                        if(!op.opciones) return false
                        if(Array.isArray(op.opciones) !== true) return false
                        if(op.opciones.length < 1) return false
                        return op.opciones.filter(ad => ad.seleccionado === true).length > 0
                    })
                    for(const adicional of encontrar_adicionales_seleccionados){
                        for( const opcion of adicional.opciones ){
                            if(opcion.seleccionado !== true) continue
                            escogidos.push(opcion.titulo)
                        }
                    }
                }
            }
        }
        return escogidos.length > 0 ? <div>
            <h6 className="mb-0">Adicionales</h6>
            {
                escogidos.map((item,i) => <span className='spanguia mr-2' style={{ background: '#665b5b', padding: "2px 8px", color: 'white', borderRadius: 4, fontSize: 13 }} key={`item-${i}`}>{item}</span>)
            }
        </div> : false
    }

    const visualizarConfiguracion = (producto) => {
        let escogidos = []
        if(producto.configuracion){
            if(Array.isArray(producto.configuracion)){
                if(producto.configuracion.length > 0){
                    const encontrar_config_seleccionado = producto.configuracion.filter(op => {
                        if(!op.opciones) return false
                        if(Array.isArray(op.opciones) !== true) return false
                        if(op.opciones.length < 1) return false
                        return op.opciones.filter(ad => ad.seleccionado === true).length > 0
                    })
                    for(const config of encontrar_config_seleccionado){
                        for( const opcion of config.opciones ){
                            if(opcion.seleccionado !== true) continue
                            escogidos.push(opcion.titulo)
                        }
                    }
                }
            }
        }
        return escogidos.length > 0 ? escogidos.map((item,i) => <span className='spanguia mr-2' key={`item-${i}`}>{item}</span>) : false

    }

    return <div>
        <h6 style={{ marginTop: 10, marginBottom: 10 }}>{pro.titulo} <span className='text-danger'>X{pro.cantidad}</span> {visualizarConfiguracion(pro)}</h6>
        {mostrarOpcionEscogida(pro)}
        {visualizarAdicionales(pro)}
    </div>
}

export default BoxProducto