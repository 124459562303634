import React, { useRef } from "react"
import { fechaATexto } from "../../lib/helpers/helpers"
import { Card, Col } from "react-bootstrap"
import Moment from "react-moment"
import BoxProducto from "../../subComponents/comandas/box_producto"
import { DateTime } from 'luxon'
const ImpresionComanda = (props) => {
    const {
        id,
        mesa,
        pedidos
    } = props
    const componentRef = useRef()

    const Impresion = React.forwardRef((props, ref) => {
        const {
            mesa,
            pedidos
        } = props
        
        const visualizarComandas = (pedidos) => {
            
            return <div>
                {
                    pedidos.map((pedido,i) => {  
                        let numero_mesa = ''             
                        if(pedido.mesa_seleccionada){
                            if(typeof pedido.mesa_seleccionada === "object"){
                                if(pedido.mesa_seleccionada.id) numero_mesa = pedido.mesa_seleccionada.id
                            }
                        }                  
                        return <div xs={12} className="" key={`pedido-${i}`} >
                                    <div >
                                {
                                    pedido.productos.map((pro,i) => {
                                        return <div key={`${pedido._id}-${i}`}>
                                            <BoxProducto size="sm" producto={pro} />
                                            {pedido.productos.length - 1 === i ? false : <hr /> }
                                        </div>
                                    })
                                }
                                </div>
                        </div>
                    })
                }
            </div>
        }
    

        const mesa_app = mesa

        if(!mesa_app) return false

        let nombre_garzon = ''

            if(pedidos) if(Array.isArray(pedidos) !== false) if(pedidos.length > 0) {
                for( const item of pedidos ){
                    if(item.user){
                        if(typeof item.user === "object"){
                            if(item.user.nombres) nombre_garzon = item.user.nombres
                        }
                    }
                }
            }
        
        const fecha = DateTime.now().setZone("America/Santiago").toISO()

        return <div ref={ref} id={id} className="hideprint" style={{ wordWrap: "break-word", textAlign: "left", width: "100%" }}>
                    <img alt="logo" id="logo" className='mb-3' style={{ width: "45%", maxWidth: 200, marginBottom: 10, margin: "0 auto", display: "block" }} src={"https://tremus.cl/wp-content/uploads/2023/02/logo_institucional_tremus.svg"}  /> 
                    <table style={{ margin: 0, width: "100%", marginBottom: 10 }}>
                        <tr>
                            <td>
                            <hr style={{ border: "1px dashed black", marginTop: 10, marginBottom: 0 }} />
                            <h5 style={{ marginTop: 15, marginBottom: 15 }}>MESA {mesa.id} · TREMUS</h5>
                            <hr style={{ border: "1px dashed black", margin: 0 }} /></td>
                        </tr>
                    </table>
                    {visualizarComandas(pedidos)}
                    <hr style={{ border: "1px solid black" }} />
                    <h6 style={{ marginBottom: 0, marginTop: 0 }} >Atendido por {nombre_garzon}</h6>
                    <h6 style={{ marginBottom: 10, marginTop: 10 }} >{fecha}</h6>
                    <h6 style={{ paddingBottom: 25, marginTop: 0 }} >WWW.TREMUS.CL</h6>
                    <h1 style={{ color: "white" }}>--</h1>
                    <div style={{ pageBreakAfter: "always" }}></div>
    </div>
    })


    return <div>
        <Impresion ref={componentRef} mesa={mesa} pedidos={pedidos} />
    </div>

}

export default ImpresionComanda