import React, { Component} from 'react'
import { connect } from 'react-redux'
import { Row, Col, Card, Button, Nav, Navbar, Container } from 'react-bootstrap'
import Header from '../Header'
import Spinner from 'react-bootstrap/Spinner'
import { toast } from 'react-toastify';
import 'moment/locale/es';
import data from '../../lib/backend/data'
import { Link } from 'react-router-dom'

class EstablecimientoDetalles extends Component {
    constructor(props){
        super(props)
        this.state = {
            id_establecimiento: this.props.match.params.id,
            user: JSON.parse(localStorage.getItem('bumer_bknd_user')),
            establecimiento: false,
            loadingEstablecimiento: true,
            toaststyle: { position: toast.POSITION.BOTTOM_RIGHT },
            primer_registro: false,
            filtro: '',
            eliminando: false,
            menus: [],
            title: ''
        }
        this.handleChange = this.handleChange.bind(this)
    }
    
    handleChange(e){
        const { name, value } = e.target
        return this.setState({ [name]: value })
    }

    componentDidMount(){
        const { id_establecimiento } = this.state
        this.getEstablecimiento(id_establecimiento)
    }

    getEstablecimiento(id){
        this.setState({ loadingEstablecimiento: true })
        return fetch(`${data.urlapi}/api/establecimientos/details?id=${id}`)
        .then(res => res.json())
        .then(res => {
            console.log(res)
            this.setState({ loadingEstablecimiento: false, establecimiento: res.dato, menus: res.menus })
        })
        .catch(error => {
            this.setState({ loadingEstablecimiento: false })
            toast.error("Error al consultar la información, intente nuevamente", this.state.toaststyle)
        })
    }

    sinRegistros(){
        return <div className='p-5'>
            <Row className="justify-content-md-center">
                <Col md={6} className="text-center">
                <h4>No has creado tu primer establecimiento</h4>
                <p>Un establecimiento es un lugar físico donde ofreces tus productos, restaurante, bar o restobar. Una vez que lo crees podrás crear una o varias cartas online para que tus clientes puedan acceder a ellas con tu código QR</p>
                <Link to="/crear-establecimiento" className='text-white' ><Button variant="primary" >CREAR ESTABLECIMIENTO</Button></Link>
                </Col>
            </Row>
        </div>
    }

    mostrarDatos(){
        const { loadingEstablecimiento, establecimiento, id_establecimiento, menus } = this.state

        if(loadingEstablecimiento){
            return <div>
                <Spinner animation='grow' />
                <h3>Cargando información</h3>
            </div>
        }

        return <div>
            <Card style={{ width: '100%' }}>
                
                
                    <Row>
                        <Col md={12}>
                        <Card.Body>
                        <p className='mb-0'>Detalles de la sucursal</p>
                        <h1 className="mb-0">{establecimiento.titulo}</h1>
                        </Card.Body>
                        </Col>
                        <Col xs={12}>
                        <Navbar bg="light" expand="lg">
                            <Container fluid>
                            <Navbar.Brand className='mr-3'>Opciones</Navbar.Brand>
                            <Navbar.Toggle aria-controls="basic-navbar-nav" />
                            <Navbar.Collapse id="basic-navbar-nav">
                                <Nav className="me-auto">
                                <Nav.Link ><Link className='mr-4' to={`editar-establecimiento-${id_establecimiento}`} >Editar</Link></Nav.Link>
                                <Nav.Link ><Link className='mr-4' to={`adicionales-${id_establecimiento}`} >Adicionales</Link></Nav.Link>
                                <Nav.Link ><Link className='mr-4' to={`mesas-${id_establecimiento}`} >Mesas</Link></Nav.Link>
                                <Nav.Link ><Link className='mr-4' to={`garzones-${id_establecimiento}`} >Garzones</Link></Nav.Link>
                                <Nav.Link ><Link className='mr-4' to={`payments-${id_establecimiento}`} >Medios de pago</Link></Nav.Link>
                                <Nav.Link ><Link className='mr-4' to={`orderscenter-${id_establecimiento}`} >Comandas</Link></Nav.Link>
                                <Nav.Link ><Link className='mr-4' to={`customers-${id_establecimiento}`} >Mis Clientes</Link></Nav.Link>
                                </Nav>
                            </Navbar.Collapse>
                            </Container>
                        </Navbar>
                        </Col>
                    </Row>
            </Card>

            <Row className='mt-3'>
                <Col md={12} >
                    <h2><i className="far fa-file-alt"></i> Cartas</h2>
                    <hr />
                    {this.mostrarMenus(menus)}
                </Col>
                
            </Row>

        </div>
    }

    crearCarta(){
        const { title, id_establecimiento } = this.state

        const payload = {
            title,
            id_restaurant: id_establecimiento
        }
        if(!payload.title) return toast.error('El título es obligatorio')

        this.setState({ creando: true })
        return fetch(`${data.urlapi}/api/menus/new`,{
            method: 'POST',
            body: JSON.stringify(payload),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(res => res.json())
        .then(res => {
            this.setState({ creando: false })
            this.getEstablecimiento(id_establecimiento)
            return toast.success('Creado exitosamente, espere mientras redirigimos')
        })
        .catch(error => {
            this.setState({ creando: false })
            toast.error("Error al consultar la información, intente nuevamente")
        })
    }

    mostrarMenus(menus){
        const { creando } = this.state

        if(creando){
            return <div>
                <Spinner animation='grow' />
                <h3>Cargando...</h3>
            </div>
        }

        if(menus.length < 1) return <div>

        <Row className="justify-content-md-center">
                <Col md={4} className="text-center mb-3">
                    <img alt="cartas" width={250} src="images/cartas.png" />
                <h4>Aún no has creado tu primera carta</h4>
                <p>Por cada uno de tus establecimientos puedes crear más de una carta</p>
                <input className='form-control mb-3' name="title" placeholder='Nombre de la carta' onChange={this.handleChange} />
                <Button variant="outline-primary" style={{ width: '100%' }} onClick={()=>this.crearCarta()}>CREAR CARTA</Button>
                </Col>
            </Row>

        </div>

        return <Row>
            <Col md={2}>
            <p>Por cada uno de tus establecimientos puedes crear más de una carta</p>
            <input className='form-control mb-3' name="title" placeholder='Nombre de la carta' onChange={this.handleChange} />
            <Button variant="outline-primary" size="sm" className='mb-3' style={{ width: '100%' }} onClick={()=>this.crearCarta()}>CREAR CARTA</Button>
            </Col>
            <Col md="12">
                <h4>{menus.length} Cartas</h4>
            </Col>
            {
                menus.map((menu,i) => {
                    return <Col md={3} className='text-center mb-3' key={`menu-${i}`}>
                            <i style={{ fontSize: 30 }} class="far fa-file-alt"></i>
                            <h2 >{menu.title}</h2>
                            <Link to={`/carta-${menu._id}`}><Button variant="outline-primary" size="sm">VER CARTA</Button> </Link>                            
                </Col>
                })
            }
        </Row>
    }
    
    render(){
        return(
            <div className="fluid">
       <Header/>
       <div className="container-fluid">
  <div className="row">
    <main className="col-md-12 ms-sm-auto col-lg-12 px-md-4 leftauto">
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
        <Link to="/establecimientos" className='text-dark' ><i className="fas fa-chevron-left"></i> Volver a sucursales</Link>
      </div>
      {this.mostrarDatos()}
    </main>
  </div>
</div>
    </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        miusuario: state.miusuario
    }
}

export default connect(mapStateToProps)(EstablecimientoDetalles);