import React, { Component} from 'react'
import { connect } from 'react-redux'
import { Row, Col, Card, Button } from 'react-bootstrap'
import Header from '../Header'
import Spinner from 'react-bootstrap/Spinner'
import { toast } from 'react-toastify';
import 'moment/locale/es';
import data from '../../lib/backend/data'
import { Link } from 'react-router-dom'
import Switch from "react-switch";
import Modal from 'react-bootstrap/Modal'
import { confirmAlert } from 'react-confirm-alert'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import { ordenarProductos } from '../../lib/helpers/helpers'
import Select from 'react-select';
import ProductosBuscador from '../../subComponents/productos.js/selector_modal'
import CargaImagenes from '../../subComponents/general/carga_imagenes'

const peso_maximo = 1500000
const process = { env: { } }

const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {props.titulo}
    </Tooltip>
  );

class CartaDetalles extends Component {
    constructor(props){
        super(props)
        this.state = {
            id_carta: this.props.match.params.id,
            user: JSON.parse(localStorage.getItem('bumer_bknd_user')),
            menu: false,
            loadingMenu: true,
            filtro: '',
            eliminando: false,
            categorias: [],
            productos: [],
            tipo_visualizacion: 'categorias',
            loadingMaster: false,
            imagen: '',
            title: '',
            nuevoproducto: {
                destacado: false
            },
            nuevacategoria: {},
            producto_seleccionado: false,
            categoria_seleccionada: false,
            id_categoria: false,
            showmodal: false,
            actualizando: false,
            show_modal_nuevo_producto: false,
            show_modal_categoria: false,
            show_modal_nueva_categoria: false,
            posiciones_actualizadas: false,
            posiciones_actualizadas_categorias: false,
            configs3: {
                dirName: "movil/",
                bucketName: process.env.S3_BUCKET_NAME,
                region: process.env.S3_BUCKET_REGION,
                accessKeyId: process.env.S3_ACCESS_KEY_ID,
                secretAccessKey: process.env.S3_SECRET_ACCESS_KEY,
            },
            subiendofoto: false,
            loadingAdicionales: true,
            adicionales: [],
            adicional_seleccionado: false,
            nombreCategoria: ""
        }
        this.handleChange = this.handleChange.bind(this)
        this.handleChangeProducto = this.handleChangeProducto.bind(this)
        this.handleChangeEdicionProducto = this.handleChangeEdicionProducto.bind(this)
        this.handleChangeChecked = this.handleChangeChecked.bind(this)
        this.handleChangeCheckedEdicionProducto = this.handleChangeCheckedEdicionProducto.bind(this)
        this.handleChangeCheckedActivoProducto = this.handleChangeCheckedActivoProducto.bind(this)
        this.handleChangeEdicionCategoria = this.handleChangeEdicionCategoria.bind(this)
        this.handleChangeOpcionesProducto = this.handleChangeOpcionesProducto.bind(this)
        this.handleChangeSelectAdicional = this.handleChangeSelectAdicional.bind(this)
        this.handleChangeConfiguracion = this.handleChangeConfiguracion.bind(this)
        this.handleChangeOpcionesConfigProducto = this.handleChangeOpcionesConfigProducto.bind(this)
        this.handleChangeAgrupado = this.handleChangeAgrupado.bind(this)
        this.handleChangeCheckedInventario = this.handleChangeCheckedInventario.bind(this)
        this.handleChangeCheckedCustom = this.handleChangeCheckedCustom.bind(this)
        this.handleChanteNombreCategoria = this.handleChanteNombreCategoria.bind(this)
        this.handleChangeAgrupadoDinamico = this.handleChangeAgrupadoDinamico.bind(this)

    }

    handleChangeCheckedInventario(checked){
        const { producto_seleccionado } = this.state
        producto_seleccionado.manage_stock = checked
        if(checked !== true) producto_seleccionado.stock = 0
        return this.setState({ producto_seleccionado })
    }

    handleChangeCheckedCustom(checked,name){
        const { producto_seleccionado } = this.state
        producto_seleccionado[name] = checked
        return this.setState({ producto_seleccionado })
    }

    handleChangeChecked(checked){
        const { nuevoproducto } = this.state
        nuevoproducto.destacado = checked
        return this.setState({ nuevoproducto })
    }

    handleChangeSelectAdicional(e){
        return this.setState({ adicional_seleccionado: e.value })
    }

    handleChangeCheckedEdicionProducto(checked){
        const { producto_seleccionado} = this.state
        producto_seleccionado.destacado = checked
        return this.setState({ producto_seleccionado })
    }

    handleChangeCheckedActivoProducto(checked){
        const { producto_seleccionado} = this.state
        producto_seleccionado.active = checked
        return this.setState({ producto_seleccionado })
    }

    handleChangeProducto(e){
        const { name, value } = e.target
        const { nuevoproducto } = this.state
        nuevoproducto[name] = value
        return this.setState({ nuevoproducto })
    }

    handleChanteNombreCategoria(e){
        const { value } = e.target
        return this.setState({ nombreCategoria: value })
    }

    handleChangeAgrupadoDinamico(e){
        const { name, value } = e.target
        const pos = e.target.getAttribute('pos')
        const icat = e.target.getAttribute('icat')
        const { producto_seleccionado } = this.state 
        producto_seleccionado.categorias[icat].opciones[pos][name] = value
        return this.setState({ producto_seleccionado })
    }

    handleChangeEdicionCategoria(e){
        const { name, value } = e.target
        const { categoria_seleccionada } = this.state
        categoria_seleccionada[name] = value
        return this.setState({ categoria_seleccionada })
    }

    handleChangeOpcionesConfigProducto(e){
        const { name, value } = e.target
        const pos = e.target.getAttribute('pos')
        const pos_config = e.target.getAttribute('posconfig')
        const { producto_seleccionado } = this.state
        producto_seleccionado.configuracion[pos_config].opciones[pos][name] = value        
        return this.setState({ producto_seleccionado })
    }

    handleChangeEdicionProducto(e){
        const { name, value } = e.target
        const { producto_seleccionado } = this.state
        producto_seleccionado[name] = value
        if(name === "tipo"){
            if(value === "compuesto"){
                if(!producto_seleccionado.agrupados) producto_seleccionado.agrupados = [{}]
            }
        }
        return this.setState({ producto_seleccionado })
    }
    
    handleChange(e){
        const { name, value } = e.target
        return this.setState({ [name]: value })
    }

    componentDidMount(){
        const { id_carta } = this.state
        this.getMenu(id_carta)
    }

    getAdicionales(id){
        const loading = 'loadingAdicionales'
        this.setState({ [loading]: true })
        return fetch(`${data.urlapi}/api/adicionales/list-adicionales?idestablecimiento=${id}`)
        .then(res => res.json())
        .then(res => {
            console.log(res)
            if(!res){
                toast.error('No se obtuvieron datos del servidor')
                return this.setState({ [loading]: false })
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return this.setState({ [loading]: false })
            }
            this.setState({ [loading]: false, adicionales: res })
        })
        .catch(error => {
            this.setState({ [loading]: false })
            toast.error("Error al consultar la información, intente nuevamente", this.state.toaststyle)
        })
    }

    getMenu(id){
        const loading = 'loadingMenu'
        this.setState({ [loading]: true })
        return fetch(`${data.urlapi}/api/menus/details?id=${id}`)
        .then(res => res.json())
        .then(res => {
            if(!res){
                toast.error('No se obtuvieron datos del servidor')
                return this.setState({ [loading]: false })
            }
            if(res.errorMessage){
                toast.error(res.errorMessage)
                return this.setState({ [loading]: false })
            }
            if(res.menu){
                this.getAdicionales(res.menu.id_restaurant)
            }
            const categorias = ordenarProductos(res.categorias)
            this.setState({ [loading]: false, menu: res.menu, categorias })
        })
        .catch(error => {
            this.setState({ [loading]: false })
            toast.error("Error al consultar la información, intente nuevamente", this.state.toaststyle)
        })
    }

    getProductosDeCategoria(id,nombre){
        this.setState({ loadingMaster: true, categoria_seleccionada: nombre })
        return fetch(`${data.urlapi}/api/productos/list?id_categoria=${id}`)
        .then(res => res.json())
        .then(res => {
            const productos = ordenarProductos(res)
            return this.setState({ loadingMaster: false, productos, tipo_visualizacion: 'productos', id_categoria: id })
        })
        .catch(error => {
            this.setState({ loadingMaster: false })
            return toast.error("Error al consultar la información, intente nuevamente", this.state.toaststyle)
        })
    }

    sinRegistros(){
        return <div className='p-5'>
            <Row className="justify-content-md-center">
                <Col md={6} className="text-center">
                <h4>No has creado tu primer establecimiento</h4>
                <p>Un establecimiento es un lugar físico donde ofreces tus productos, restaurante, bar o restobar. Una vez que lo crees podrás crear una o varias cartas online para que tus clientes puedan acceder a ellas con tu código QR</p>
                <Link to="/crear-establecimiento" className='text-white' ><Button variant="primary" >CREAR ESTABLECIMIENTO</Button></Link>
                </Col>
            </Row>
        </div>
    }

    mostrarDatos(){
        const { loadingMenu, id_carta, menu } = this.state

        if(loadingMenu){
            return <div>
                <Spinner animation="border" />
                <h3>Cargando información</h3>
            </div>
        }

        return <div>
            
                <Card style={{ width: '100%' }}>
                <Row>
                <Col md={6}>
                <Card.Body>
                    <p className='mb-0'>Detalles de la carta</p>
                    <h1 className="mb-0">{menu.title} </h1>
                    {this.ifactive(menu.active)}
                    <Link to={`editar-carta-${id_carta}`} >Editar</Link>
                    <hr className='mb-3' />
                    <button className="btn btn-sm btn-danger" onClick={() => this.solicitarEliminarCarta()}>ELIMINAR</button>
                </Card.Body>
                </Col>
                </Row>
                </Card>
                

            <Row className='mt-3'>
                <Col md={12} >
                    <h2><i className="far fa-hand-pointer"></i> Categorías</h2>
                    <hr />
                    {this.mostrarTipoVisualizacion()}
                </Col>
                
            </Row>

        </div>
    }

    ifactive(estado){
        const style = {}
        if(!estado) return <p style={style} className='text-danger mb-0'><b>Inactivo</b></p>
        return <p style={style} className='text-success mb-0'><b>Activo</b></p>
    }

    mostrarTipoVisualizacion(){
        const { tipo_visualizacion, categorias, productos, loadingMaster } = this.state

        if(loadingMaster){
            return <div>
                <Spinner animation="border" />
                <h3>Cargando...</h3>
            </div>
        }

        if(tipo_visualizacion==='categorias') return this.mostrarCategorias(categorias)
        if(tipo_visualizacion==='productos') return this.mostrarProductos(productos)
    }

    destacadoIcon(destacado){
        const style= { fontSize: 11 }
        if(destacado === true) return <span style={style} className='text-warning'><i className="far fa-star"></i> Destacado</span>
        return false
    }

    activoIcon(status){
        const style= { fontSize: 11 }
        if(status === true) return <span style={style} className='text-success'><i className="fas fa-circle"></i> Activo</span>
        return <span style={style} className='text-danger'><i className="fas fa-circle"></i> Inactivo</span>
    }

    crearNuevo(){
        return this.setState({ show_modal_nuevo_producto: true })
    }

    accionesGuardarOrdenCategorias(){
        const { posiciones_actualizadas_categorias } = this.state
        if(!posiciones_actualizadas_categorias) return false

        return <Button className="mb-3" size="sm" variant="success" onClick={()=>this.guardarOrdenCategorias()} ><i className="fas fa-random"></i> GUARDAR ORDEN</Button>
    }

    accionesGuardarOrdenProductos(){
        const { posiciones_actualizadas } = this.state
        if(!posiciones_actualizadas) return false

        return <Button className="mb-3" size="sm" variant="success" onClick={()=>this.guardarOrden()} ><i className="fas fa-random"></i> GUARDAR ORDEN</Button>
    }

    guardarOrdenCategorias(){
        const { categorias } = this.state
        let registros = []
        categorias.map((pro,i) => {
            return registros.push({ _id: pro._id, orden: i })
        })
        this.setState({ loadingMaster: true })
        return fetch(`${data.urlapi}/api/categorias/reorder`,{
            method: 'POST',
            body: JSON.stringify(registros),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(res => res.json())
        .then(res => {
            this.setState({ loadingMaster: false, posiciones_actualizadas_categorias: false })
            return toast.success('Guardado exitosamente')
        })
        .catch(error => {
            this.setState({ loadingMaster: false })
            toast.error("Error al consultar la información, intente nuevamente")
        })

    }

    guardarOrden(){
        const { productos } = this.state
        let registros = []
        productos.map((pro,i) => {
            return registros.push({ _id: pro._id, orden: i })
        })
        this.setState({ loadingMaster: true })
        return fetch(`${data.urlapi}/api/productos/reorder`,{
            method: 'POST',
            body: JSON.stringify(registros),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(res => res.json())
        .then(res => {
            this.setState({ loadingMaster: false, posiciones_actualizadas: false })
            return toast.success('Guardado exitosamente')
        })
        .catch(error => {
            this.setState({ loadingMaster: false })
            toast.error("Error al consultar la información, intente nuevamente")
        })
    }

    mostrarProductos(productos){
        const { creando, categoria_seleccionada } = this.state
        if(creando){
            return <div>
                <Spinner animation="border" />
                <h3>Cargando...</h3>
            </div>
        }
        if(productos.length < 1) return <div>
        <Row>
            <Col md={12}>
                <h6 onClick={()=>this.setState({ tipo_visualizacion:'categorias' }) } className='hover' ><i className="fas fa-chevron-left"></i> Volver a categorías</h6>
                <h2><i className="fas fa-tag"></i> Productos de {categoria_seleccionada}</h2>
                <hr />
            </Col>
        </Row>

        <Row className="justify-content-md-center mb-5">
                <Col md={4} className="text-center">
                <img alt="productos" width={250} src="images/productos.png" />
                <h4>Aún no has creado tu primer producto para {categoria_seleccionada}</h4>
                <p>Puedes crear una cantidad indeterminada de productos para cada categoría</p>
                {this.formularioNuevoProducto()}
                </Col>
            </Row>
        </div>
        return <div>

            <Row>
            <Col md={12}>
                <h6 onClick={()=>this.setState({ tipo_visualizacion:'categorias' }) } className='hover'><i className="fas fa-chevron-left"></i> Volver a categorías</h6>
                <h2><i className="fas fa-tag"></i> {productos.length} Productos de {categoria_seleccionada} <Button size="sm" variant="outline-primary" onClick={()=>this.crearNuevo()} >CREAR NUEVO</Button></h2>
                <hr />
                {this.accionesGuardarOrdenProductos()}
            </Col>
            {
                productos.map((pro,ipro) => {
                    return <Col md={3} className='mb-3' key={`user${ipro}`}>
                    <Card >
                        
                    <Card.Img variant="top" src={pro.imagen} />
                    <Card.Body>
                    <Row>
                    <Col md={6}>{ ipro === 0 ? false : 
                    <OverlayTrigger
                        placement="right"
                        delay={{ show: 250, hide: 400 }}
                        overlay={renderTooltip({ titulo: "Subir de posición"})}
                    >
                    <i className="fas fa-arrow-up hover" onClick={()=>this.moverPosicion(ipro,'subir')} ></i>
                    </OverlayTrigger> 
                    }</Col>
                    <Col md={6} className='text-right' >{ ipro+1 === productos.length ? false :
                    <OverlayTrigger
                    placement="right"
                    delay={{ show: 250, hide: 400 }}
                    overlay={renderTooltip({ titulo: "Bajar de posición"})}
                >
                <i className="fas fa-arrow-down hover" onClick={()=>this.moverPosicion(ipro,'bajar')}></i>
                </OverlayTrigger>   }</Col>
                    <Col xs={6}>{this.activoIcon(pro.active)}</Col>
                    <Col xs={6} className='text-right'>{this.destacadoIcon(pro.destacado)}</Col>
                    </Row>
                    <h5 className="mb-0"> {pro.titulo}</h5>
                    <h5 className="mb-0" style={{ color: "#a3a3a3" }}>SKU: {pro.sku}</h5>
                    <h5 >CLP ${Intl.NumberFormat("es-ES").format(pro.precio)}</h5> 
                    {this.accionesProducto(pro)}
                    </Card.Body>
                </Card>
                </Col>
                })
            }
            </Row>
        </div>
    }

    

    moverPosicionCategoria(pos,accion){
        const { categorias, posiciones_actualizadas_categorias } = this.state
        if(!posiciones_actualizadas_categorias) toast.info('Al terminar de ordenar los items has click en GUARDAR ORDEN')
        if(accion==='subir'){
            const anterior = categorias[pos-1]
            categorias[pos-1] = categorias[pos]
            categorias[pos] = anterior
        } else if(accion==='bajar'){
            const anterior_pro = categorias[pos+1]
            categorias[pos+1] = categorias[pos]
            categorias[pos] = anterior_pro
        }
        return this.setState({ categorias, posiciones_actualizadas_categorias: true })
    }

    moverPosicion(pos,accion){
        const { productos, posiciones_actualizadas } = this.state
        if(!posiciones_actualizadas) toast.info('Al terminar de ordenar los items has click en GUARDAR ORDEN')
        if(accion==='subir'){
            const anterior = productos[pos-1]
            productos[pos-1] = productos[pos]
            productos[pos] = anterior
        } else if(accion==='bajar'){
            const anterior_pro = productos[pos+1]
            productos[pos+1] = productos[pos]
            productos[pos] = anterior_pro
        }
        return this.setState({ productos, posiciones_actualizadas: true })
    }

    editarProducto(id){
        this.setState({ loadingMaster: true })
        return fetch(`${data.urlapi}/api/productos/details?id=${id}`)
        .then(res => res.json())
        .then(res => {
            this.setState({ loadingMaster: false, producto_seleccionado: res, showmodal: res ? true : false })
        })
        .catch(error => {
            this.setState({ loadingMaster: false })
            return toast.error("Error al consultar la información, intente nuevamente", this.state.toaststyle)
        })
    }

    editarCategoria(id){
        this.setState({ loadingMaster: true })
        return fetch(`${data.urlapi}/api/categorias/details?id=${id}`)
        .then(res => res.json())
        .then(res => {
            this.setState({ loadingMaster: false, categoria_seleccionada: res, show_modal_categoria: res ? true : false })
        })
        .catch(error => {
            this.setState({ loadingMaster: false })
            return toast.error("Error al consultar la información, intente nuevamente", this.state.toaststyle)
        })
    }

    accionesProducto(producto){
        const { eliminando } = this.state
        if(eliminando) return <Spinner animation="border" />
        return <div>
            <Button size="sm" variant='primary' className="mr-3" onClick={()=>this.editarProducto(producto._id)} >EDITAR</Button>
            <Button size="sm" variant='outline-danger' className="mr-3" onClick={()=>this.solicitarEliminadoProducto(producto._id)} >ELIMINAR</Button>
        </div>
    }

    solicitarEliminarCarta(id){
        return confirmAlert({
            title: 'ELIMINAR PERMANENTEMENTE',
            message: `Estás a punto de eliminar la carta junto con sus categorías y productos. Esta acción no se puede deshacer.`,
            buttons: [
              {
                label: 'CONFIRMAR',
                onClick: () => this.eliminarCartaCompleta(id)
              },
              {
                label: 'CANCELAR',
                onClick: () => false
              },
            ],
          })
    }

    solicitarEliminarCategoria(id){
        return confirmAlert({
            title: '¿Estás seguro?',
            message: `¿Deseas eliminar este item? Esta opción no se puede deshacer`,
            buttons: [
              {
                label: 'CONFIRMAR',
                onClick: () => this.eliminarCategoria(id)
              },
              {
                label: 'CANCELAR',
                onClick: () => false
              },
            ],
          })
    }

    solicitarEliminadoProducto(id){
        return confirmAlert({
            title: '¿Estás seguro?',
            message: `¿Deseas eliminar este item? Esta opción no se puede deshacer`,
            buttons: [
              {
                label: 'CONFIRMAR',
                onClick: () => this.eliminarProducto(id)
              },
              {
                label: 'CANCELAR',
                onClick: () => false
              },
            ],
          })
    }

    eliminarCartaCompleta(id){
        const { categorias } = this.state
        this.setState({ loadingMaster: true })
        return fetch(`${data.urlapi}/api/carta?id=${id}`,{
            method: "DELETE",
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(res => res.json())
        .then(res => {
            const i = categorias.findIndex(pro => pro._id === id)
            if(i > -1){
                categorias.splice(i,1)
                this.setState({ categorias })
            }
            toast.success('Borrado exitosamente')
            this.setState({ loadingMaster: false, showmodal: false })
        })
        .catch(error => {
            this.setState({ loadingMaster: false })
            toast.error("Error al consultar la información, intente nuevamente", this.state.toaststyle)
        })
    }

    eliminarCategoria(id){
        const { categorias } = this.state
        this.setState({ loadingMaster: true })
        return fetch(`${data.urlapi}/api/categorias/delete?id=${id}`)
        .then(res => res.json())
        .then(res => {
            const i = categorias.findIndex(pro => pro._id === id)
            if(i > -1){
                categorias.splice(i,1)
                this.setState({ categorias })
            }
            toast.success('Borrado exitosamente')
            this.setState({ loadingMaster: false, showmodal: false })
        })
        .catch(error => {
            this.setState({ loadingMaster: false })
            toast.error("Error al consultar la información, intente nuevamente", this.state.toaststyle)
        })
    }

    eliminarProducto(id){
        const { productos } = this.state
        this.setState({ loadingMaster: true })
        return fetch(`${data.urlapi}/api/productos/delete?id=${id}`)
        .then(res => res.json())
        .then(res => {
            const i = productos.findIndex(pro => pro._id === id)
            if(i > -1){
                productos.splice(i,1)
                this.setState({ productos })
            }
            toast.success('Borrado exitosamente')
            this.setState({ loadingMaster: false, showmodal: false })
        })
        .catch(error => {
            this.setState({ loadingMaster: false })
            toast.error("Error al consultar la información, intente nuevamente", this.state.toaststyle)
        })

    }

    guardarCambiosCategoria(){
        const { categoria_seleccionada, id_carta } = this.state
        const requeridos = [ 'title' ]
        let faltantes = []
        requeridos.map(valor => !categoria_seleccionada[valor] ? faltantes.push(valor) : true)
        if(faltantes.length > 0) return toast.error(`Faltan campos por llenar ${faltantes.join(', ')}`)

        this.setState({ actualizando: true })
        return fetch(`${data.urlapi}/api/categorias/edit`,{
            method: 'POST',
            body: JSON.stringify(categoria_seleccionada),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(res => res.json())
        .then(res => {
            this.setState({ actualizando: false, show_modal_categoria: false })
            this.getMenu(id_carta)
            return toast.success('Guardado exitosamente')
        })
        .catch(error => {
            this.setState({ actualizando: false })
            toast.error("Error al consultar la información, intente nuevamente")
        })
    }

    guardarCambiosProducto(){
        const { producto_seleccionado, id_categoria, categoria_seleccionada } = this.state
        let requeridos = [ 'titulo', 'precio' ]
        if(producto_seleccionado.tipo === "compuesto"){
            requeridos = [ 'titulo' ]
            if(!producto_seleccionado.agrupados) return toast.error("No se puede guardar! Este producto es de tipo agrupado, pero no tiene opciones")
            if(Array.isArray(producto_seleccionado.agrupados) !== true) return toast.error("No se puede guardar! Este producto es de tipo agrupado, pero no tiene opciones")
            if(producto_seleccionado.agrupados.length < 1) return toast.error("No se puede guardar! Este producto es de tipo agrupado, pero no tiene opciones")
            const productos_invalidos = producto_seleccionado.agrupados.filter(p => {
                if(!p.titulo || !p.precio) return true
                const precio = parseInt(p.precio)
                return isNaN(precio) ? true : false
            })
            if(productos_invalidos.length > 0){
                return toast.error("Este producto es compuesto pero tiene items sin completar o inválidos")
            }
        } else if(producto_seleccionado.tipo === "compuesto-dinamico"){
            requeridos = [ 'titulo' ]
            if(!producto_seleccionado.categorias) return toast.error("No se puede guardar! Este producto es de tipo compuesto dinámico, pero no tiene categorías")
            if(Array.isArray(producto_seleccionado.categorias) !== true) return toast.error("No se puede guardar! Este producto es de tipo compuesto dinámico, pero no tiene categorías")
            if(producto_seleccionado.categorias.length < 1) return toast.error("No se puede guardar! Este producto es de tipo compuesto dinámico, pero no tiene categorías")
            const productos_invalidos = producto_seleccionado.categorias.filter(p => {
                if(!p.titulo) return true
                if(!p.opciones) return true
                if(Array.isArray(p.opciones) !== true) return true
                if(p.opciones.length < 1) return true
                const buscar_opciones_invalidas = p.opciones.filter(op => {
                    if(!op.titulo || !op.precio) return true
                    const precio = parseInt(op.precio)
                    return isNaN(precio) ? true : false
                })
                return buscar_opciones_invalidas.length > 0 ? true : false
            })
            if(productos_invalidos.length > 0){
                return toast.error("Este producto es compuesto dinámico pero tiene categorías u opciones sin completar o inválidos")
            }
        } else {
            if(isNaN(parseFloat(producto_seleccionado.precio)) !== false) return toast.error('Precio inválido')
        }

        // VERIFICAR STOCK
        if(producto_seleccionado.manage_stock === true){
            const cantidad = parseInt(producto_seleccionado.stock)
            if(isNaN(cantidad)) return toast.error("Valor de stock inválido")
        }

        // FALTANTES
        let faltantes = []
        requeridos.map(valor => !producto_seleccionado[valor] ? faltantes.push(valor) : true)
        if(faltantes.length > 0) return toast.error(`Faltan campos por llenar ${faltantes.join(', ')}`)

        if(producto_seleccionado.precio_oferta){
            if( producto_seleccionado.precio_oferta >  producto_seleccionado.precio) return toast.error('Precio oferta no puede ser mayor que precio normal')
        }
        if(producto_seleccionado.configuracion){
            if(Array.isArray(producto_seleccionado.configuracion) !== false){
                if(producto_seleccionado.configuracion.length > 0){
                    const filtrar_invalidos = producto_seleccionado.configuracion.filter(conf => {
                        if(!conf.titulo) return true
                        if(!conf.opciones) return true
                        if(conf.opciones.length < 2) return true
                        return conf.opciones.filter(op => !op.titulo).length > 0 ? true : false
                    })
                    if(filtrar_invalidos.length > 0) return toast.error(`Tienes ${filtrar_invalidos.length} configuraciones de producto inválidas`)
                }
            }
        }
        this.setState({ actualizando: true })
        return fetch(`${data.urlapi}/api/productos/edit`,{
            method: 'POST',
            body: JSON.stringify(producto_seleccionado),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(res => res.json())
        .then(res => {
            if(!res){
                toast.error("Sin datos del servidor")
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
            } else if(res._id){
                toast.success('Guardado exitosamente')
                this.getProductosDeCategoria(id_categoria, categoria_seleccionada)
                return this.setState({ actualizando: false, showmodal: false })
            }
            return this.setState({ actualizando: false })
        })
        .catch(error => {
            this.setState({ actualizando: false })
            toast.error("Error al consultar la información, intente nuevamente")
        })
    }

    crearProducto(){
        const { nuevoproducto, id_categoria, categoria_seleccionada } = this.state
        const requeridos = [ 'titulo', 'precio' ]
        let faltantes = []
        requeridos.map(valor => !nuevoproducto[valor] ? faltantes.push(valor) : true)
        if(faltantes.length > 0) return toast.error(`Faltan campos por llenar ${faltantes.join(', ')}`)
        if(isNaN(parseFloat(nuevoproducto.precio)) !== false) return toast.error('Precio inválido')
        if(nuevoproducto.precio_oferta){
            if( nuevoproducto.precio_oferta > nuevoproducto.precio) return toast.error('Precio oferta no puede ser mayor que precio normal')
        }
        nuevoproducto.id_categoria = id_categoria
        nuevoproducto.active = true
        this.setState({ creando: true, actualizando: true })
        return fetch(`${data.urlapi}/api/productos/new`,{
            method: 'POST',
            body: JSON.stringify(nuevoproducto),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(res => res.json())
        .then(res => {
            if(!res){
                toast.error("Sin datos del servidor")
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
            } else if(res._id) {
                toast.success('Creado exitosamente')
                this.setState({ creando: false, actualizando: false, tipo_visualizacion: 'categoria', nuevoproducto: { destacado: false }, show_modal_nuevo_producto: false })
                return this.getProductosDeCategoria(id_categoria, categoria_seleccionada)
            }
            return this.setState({ creando: false, actualizando: false })
        })
        .catch(error => {
            this.setState({ creando: false, actualizando: false })
            toast.error("Error al consultar la información, intente nuevamente")
        })
    }

    crearCategoria(){
        const { title, imagen, id_carta } = this.state

        const payload = {
            title,
            imagen,
            id_menu: id_carta
        }
        if(!payload.title) return toast.error('El título es obligatorio')

        this.setState({ creando: true })
        return fetch(`${data.urlapi}/api/categorias/new`,{
            method: 'POST',
            body: JSON.stringify(payload),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(res => res.json())
        .then(res => {
            this.setState({ creando: false, show_modal_nueva_categoria: false })
            this.getMenu(id_carta)
            return toast.success('Creado exitosamente, espere mientras redirigimos')
        })
        .catch(error => {
            this.setState({ creando: false })
            toast.error("Error al consultar la información, intente nuevamente")
        })
    }

    crearNuevaCategoria(){
        return this.setState({ show_modal_nueva_categoria: true })
    }

    mostrarCategorias(categorias){
        const { creando } = this.state

        if(creando){
            return <div>
                <Spinner animation="border" />
                <h3>Cargando...</h3>
            </div>
        }

        if(categorias.length < 1) return <div>

        <Row className="justify-content-md-center">
                <Col md={4} className="text-center">
                <img alt="categorias" width={300} src="images/categorias.png" />
                <h4>Aún no has creado tu primera categoría</h4>
                <p>Cada categoría contiene un grupo de productos, puedes categorizar tus productos como lo prefieras</p>
                <input className='form-control mb-3' name="title" placeholder='Nombre de la categoría' onChange={this.handleChange} />
                <Button variant="primary" style={{ width: '100%' }} onClick={()=>this.crearCategoria()}>CREAR CATEGORÍA</Button>
                </Col>
            </Row>

        </div>

        return <Row>
            <Col md="12">
                <h4>{categorias.length} Categorías <Button size="sm" variant='outline-primary' onClick={()=>this.crearNuevaCategoria()}>CREAR NUEVA CATEGORÍA</Button> </h4>
                {this.accionesGuardarOrdenCategorias()}
            </Col>
            {
                categorias.map((menu,i) => {
                    return <Col md={4} className='text-center mb-3' key={`menu-${i}`}>
                    <Card style={{ width: '100%' }}>
                    { menu.imagen ? <Card.Img variant="top" src={menu.imagen} /> : false }
                        <Card.Body>
                            <Row>
                            <Col md={6} className='text-left'>{ i === 0 ? false : 
                            <OverlayTrigger
                                placement="right"
                                delay={{ show: 250, hide: 400 }}
                                overlay={renderTooltip({ titulo: "Subir de posición"})}
                            >
                            <i className="fas fa-arrow-up hover" onClick={()=>this.moverPosicionCategoria(i,'subir')} ></i>
                            </OverlayTrigger> 
                            }</Col>
                            <Col md={6} className='text-right' >{ i+1 === categorias.length ? false :
                            <OverlayTrigger
                            placement="right"
                            delay={{ show: 250, hide: 400 }}
                            overlay={renderTooltip({ titulo: "Bajar de posición"})}
                            >
                            <i className="fas fa-arrow-down hover" onClick={()=>this.moverPosicionCategoria(i,'bajar')}></i>
                            </OverlayTrigger>   }
                            </Col>
                            </Row>
                            
                            <i style={{ fontSize: 30 }} className="far fa-file-alt"></i>
                            <h4 >{menu.title}</h4>
                            <Button variant="primary" size="sm" className="mr-3" onClick={()=>this.editarCategoria(menu._id)}>EDITAR</Button>
                            <Button variant="outline-primary" size="sm" className="mr-3" onClick={()=>this.getProductosDeCategoria(menu._id, menu.title)}>PRODUCTOS</Button>
                            <Button variant="outline-danger" size="sm" className="mr-3" onClick={()=>this.solicitarEliminarCategoria(menu._id)}>ELIMINAR</Button>
                        </Card.Body>
                    </Card>
                </Col>
                })
            }
        </Row>
    }

    handleClose(){
        return this.setState({ showmodal: false, show_modal_nuevo_producto: false, show_modal_categoria: false, show_modal_nueva_categoria: false })
    }

    formularioNuevoProducto(){
        const { nuevoproducto, creando, subiendofoto } = this.state
        return <div>
                <div className='form-group'>
                <input className='form-control mb-3' name="titulo" placeholder='Nombre del producto' onChange={this.handleChangeProducto} />
                </div>
                <div className='form-group'>
                <input className='form-control mb-3' name="sku" placeholder='SKU del producto' onChange={this.handleChangeProducto} />
                </div>
                <div className='form-group'>
                <textarea className='form-control mb-3' type="number" name="descripcion" placeholder='Descripción del producto' onChange={this.handleChangeProducto} ></textarea>
                </div>
                <div className='form-group'>
                <input className='form-control mb-3' type="number" name="precio" placeholder='Precio del producto' onChange={this.handleChangeProducto} />
                </div>
                <div className='form-group'>
                <input className='form-control mb-3' type="number" name="precio_oferta" placeholder='Precio oferta' onChange={this.handleChangeProducto} />
                </div>
                <div className='form-group text-left'>
                <label className="form-control-label" >Fotografía del producto</label>
                {/* <input className='form-control mb-3' type="file" name="imagen" onChange={this.handleChangeNuevoProducto} /> */}
                <CargaImagenes onUploaded={(url) => {
                    nuevoproducto.imagen = url
                    return this.setState({ nuevoproducto })
                }} />
                { subiendofoto ? <Spinner animation='grow' /> : false } 
                {  nuevoproducto.imagen ? <img alt="imagen" src={ nuevoproducto.imagen} width={300} /> : false }
                </div>
                <div className='form-group text-left'>
                <label className="form-control-label d-block" >Destacar producto</label>
                <Switch onChange={this.handleChangeChecked} checked={nuevoproducto.destacado} />
                </div>
                { creando ? <Spinner animation='grow' /> : <Button variant="primary" style={{ width: '100%' }} onClick={()=>this.crearProducto()}>CREAR PRODUCTO</Button> }

        </div>
    }

    anadirOpcionAccion(){
        const { producto_seleccionado } = this.state
        if(!producto_seleccionado.opciones) producto_seleccionado.opciones = []
        producto_seleccionado.opciones.push({
            titulo: '',
            precio: 0,
            seleccionado: false
        })
        return this.setState({ producto_seleccionado })
    }

    botonAnadirOpcion(){
        return <Button variant="outline-primary" size="sm" className='mt-2 mb-2' onClick={()=>this.anadirOpcionAccion()} >AÑADIR OPCIÓN</Button>
    }

    mensajeSinProductos(){
        return <div>
            <h6>Sin opciones seleccionadas</h6>
            {this.botonAnadirOpcion()}
        </div> 
    }

    

    mensajeSinAdicionales(){
        return <div >
            {this.formAdicionales()}
            <h6>Sin adicionales seleccionados</h6>
        </div> 
    }

    handleChangeConfiguracion(e){
        const { name, value } = e.target
        const pos = e.target.getAttribute('pos')
        const { producto_seleccionado } = this.state
        if(name==='titulo'){
            producto_seleccionado.configuracion[pos][name] = value
        } else {
            producto_seleccionado.configuracion[pos][name] = value.replace(/\D/g, "")
        }
        console.log(producto_seleccionado)
        return this.setState({ producto_seleccionado  })
    }

    handleChangeOpcionesProducto(e){
        const { name, value } = e.target
        const pos = e.target.getAttribute('pos')
        const { producto_seleccionado } = this.state
        if(name==='titulo'){
            producto_seleccionado.opciones[pos][name] = value
        } else {
            producto_seleccionado.opciones[pos][name] = value.replace(/\D/g, "")
            console.log(value.replace(/\D/g, ""))
        }
        return this.setState({ producto_seleccionado  })
    }

    eliminarOpcion(i){
        const { producto_seleccionado } = this.state
        producto_seleccionado.opciones.splice(i,1)
        return this.setState({ producto_seleccionado })
    }

    agregarAdicional(){
        const { producto_seleccionado, adicional_seleccionado } = this.state
        if(!adicional_seleccionado) return toast.error('Selecciona primero un adicional para poder agregarlo')
        if(!producto_seleccionado.adicionales) producto_seleccionado.adicionales = []
        if(producto_seleccionado.adicionales.includes(adicional_seleccionado) !== false) return false
        producto_seleccionado.adicionales.push(adicional_seleccionado)
        return this.setState({ producto_seleccionado, adicional_seleccionado: false })
    }

    formAdicionales(){
        const { adicionales, loadingAdicionales } = this.state
        const options = adicionales.map(ad => ({ value: ad._id, label: ad.titulo }))

        return <div className='mb-3'>
            <Row>
                <Col md={8}>
                <label className='form-control-label'>Selecciona el producto</label>
                <Select 
                style={{ marginBottom: 10 }}
                onChange={this.handleChangeSelectAdicional}
                options={options}
                loading={loadingAdicionales}
                defaultValue={''}
                placeholder="Buscar ..."
              />
                </Col>
                <Col md={4}>
                <label className='form-control-label d-block'>Click para agregar</label>
                <Button variant="primary" onClick={()=>this.agregarAdicional()}>AGREGAR</Button>
                </Col>
            </Row>
            
        </div>
    }

    eliminarAdicional(i){
        const { producto_seleccionado } = this.state
        producto_seleccionado.adicionales.splice(i,1)
        return this.setState({ producto_seleccionado })
    }
    formularioAdicionalesProducto(producto){
        const { adicionales } = this.state
        if(!producto.adicionales) return this.mensajeSinAdicionales()
        if(Array.isArray(producto.adicionales) !== true) return this.mensajeSinAdicionales()
        if(producto.adicionales.length < 1) return this.mensajeSinAdicionales()

        return <div>
            {this.formAdicionales()}
            {
                producto.adicionales.map((idacicional,i) => {
                    const pos = adicionales.findIndex(ad => ad._id === idacicional)
                    if(pos < 0) return <div key={idacicional}><h4>No disponible</h4><Button size="sm" variant="outline-danger" onClick={()=>this.eliminarAdicional(i)}>ELIMINAR</Button></div>
                    const adicional = adicionales[pos]
                    return <div key={idacicional}>
                        <Row>
                            <Col xs={4}><h5>{adicional.titulo}</h5></Col>
                            <Col xs={4}><h5>CLP ${Intl.NumberFormat("es-ES").format(adicional.precio)}</h5></Col>
                            <Col xs={4}><Button size="sm" variant="outline-danger" onClick={()=>this.eliminarAdicional(i)}>ELIMINAR</Button></Col>
                        </Row>
                        { i+1 === producto.adicionales.length ? false : <hr className='hr' /> }
                        </div>
                })
            }
        </div>
    }

    mostrarOpcionesConfig(opciones,pos){
        if(!opciones) return <h4 className='mt-2'>No hay opciones</h4>
        if(Array.isArray(opciones) !== true) return <h4 className='mt-2'>No hay opciones</h4>
        if(opciones.length < 1) return <h4 className='mt-2'>No hay opciones</h4>

        return <div>
            {
                opciones.map((opcion,i) => {
                    return <Row>
        <Col xs={1}>
        <OverlayTrigger
        key={`pos${i}`}
        placement={'bottom'}
        overlay={
            <Tooltip id={`tooltip-right`}>ELIMINAR</Tooltip>
        }
        >
        <i className="far fa-trash-alt hover" style={{ paddingTop: 40 }} onClick={()=>this.eliminarConfOpcion(i,pos)}></i>
        </OverlayTrigger>
        
        </Col>
        <Col xs={11}>
        <label className='form-control-label'>Nombre</label>
        <input className='form-control' name="titulo" pos={i} posconfig={pos} onChange={this.handleChangeOpcionesConfigProducto} value={opcion.titulo} />
        </Col>
    </Row>
                })
            }
        </div>
    }

    mensajeSinConfiguracion(){
        return <div>
            <h6>Sin configuración seleccionada</h6>
            {this.botonAnadirConfiguracion()}
        </div> 
    }

    anadirConfiguracion(){
        const { producto_seleccionado } = this.state
        if(!producto_seleccionado.configuracion) producto_seleccionado.configuracion = []
        producto_seleccionado.configuracion.push({
            titulo: '',
            opciones: []
        }) 
        return this.setState({ producto_seleccionado })
    }

    botonAnadirConfiguracion(){
        return <Button variant="outline-primary" size="sm" className='mb-2' onClick={()=>this.anadirConfiguracion()} >AÑADIR CONFIGURACIÓN</Button>
    }

    eliminarConfOpcion(i,pos){
        const { producto_seleccionado } = this.state
        producto_seleccionado.configuracion[pos].opciones.splice(i,1)
        return this.setState({ producto_seleccionado })
    }
    eliminarConf(i){
        const { producto_seleccionado } = this.state
        producto_seleccionado.configuracion.splice(i,1)
        return this.setState({ producto_seleccionado })
    }

    anadirOpcionConfig(i){
        const { producto_seleccionado } = this.state
        producto_seleccionado.configuracion[i].opciones.push({
            titulo: ''
        })
        return this.setState({ producto_seleccionado })
    }

    formularioConfiguracionesProducto(producto){
        if(!producto.configuracion) return this.mensajeSinConfiguracion()
        if(Array.isArray(producto.configuracion) !== true) return this.mensajeSinConfiguracion()
        if(producto.configuracion.length < 1) return this.mensajeSinConfiguracion()

        return <div>
            {this.botonAnadirConfiguracion()}
            {
                producto.configuracion.map((opcion,i) => {
                    return <div key={`config-producto-${i}`}>
                        <Row>
                            <Col xs={1}>
                            <OverlayTrigger
                            key={`pos${i}`}
                            placement={'bottom'}
                            overlay={
                                <Tooltip id={`tooltip-right`}>ELIMINAR</Tooltip>
                            }
                            >
                            <i className="far fa-trash-alt hover" style={{ paddingTop: 40 }} onClick={()=>this.eliminarConf(i)}></i>
                            </OverlayTrigger>
                            
                            </Col>
                            <Col xs={7}>
                                <label className='form-control-label d-block'>Título</label>
                                <input className='form-control' name="titulo" pos={i} onChange={this.handleChangeConfiguracion} placeholder='Escribe el título de la opción' value={opcion.titulo} />
                            </Col>
                            <Col xs={4}>
                                <label className='form-control-label d-block'>Click para agregar</label>
                                <Button variant="primary" size="sm" onClick={()=>this.anadirOpcionConfig(i)} >AÑADIR OPCIÓN</Button>
                                </Col>
                            <Col xs={12}>{this.mostrarOpcionesConfig(opcion.opciones, i)}</Col>
                            { producto.configuracion.length === i+1 ? false : <Col xs={12}><hr /></Col> }
                        </Row>
                        
                        
                    </div>
                })
            }
        </div>
    }

    formularioOpcionesProducto(producto){
        if(!producto.opciones) return this.mensajeSinProductos()
        if(Array.isArray(producto.opciones) !== true) return this.mensajeSinProductos()
        if(producto.opciones.length < 1) return this.mensajeSinProductos()

        return <div>

            {
                producto.opciones.map((opcion,i) => {
                    return <div key={`opcion-producto-${i}`}>
                        <Row>
                            <Col xs={1}>
                            <OverlayTrigger
                            key={`pos${i}`}
                            placement={'bottom'}
                            overlay={
                                <Tooltip id={`tooltip-right`}>ELIMINAR</Tooltip>
                            }
                            >
                            <i className="far fa-trash-alt hover" style={{ paddingTop: 40 }} onClick={()=>this.eliminarOpcion(i)}></i>
                            </OverlayTrigger>
                            
                            </Col>
                            <Col xs={5}>
                            <label className='form-control-label'>Nombre</label>
                            <input className='form-control' name="titulo" pos={i} onChange={this.handleChangeOpcionesProducto} placeholder='Escribe el nombre de la opción' defaultValue={opcion.titulo} />
                            </Col>
                            <Col xs={6}>
                            <label className='form-control-label'>Precio</label>
                            <input className='form-control' name="precio" pos={i} onChange={this.handleChangeOpcionesProducto} placeholder='Escribe el precio de esta opción' type="number" defaultValue={opcion.precio} />
                            </Col>
                            { producto.opciones.length === i+1 ? false : <Col xs={12}><hr /></Col> }
                        </Row>
                        
                        
                    </div>
                })
            }
            {this.botonAnadirOpcion()}
        </div>

    }

    eliminarFotoPro(){
        const { producto_seleccionado } = this.state
        producto_seleccionado.imagen = ''
        return this.setState({ producto_seleccionado })
    }

    handleChangeAgrupado(e) {
        const { name, value } = e.target
        const pos = e.target.getAttribute('pos')
        const { producto_seleccionado } = this.state
        producto_seleccionado.agrupados[pos][name] = value        
        return this.setState({ producto_seleccionado })
    }

    anadirAgrupacion(){
        const { producto_seleccionado } = this.state
        producto_seleccionado.agrupados.push({
            titulo: "",
            sku: "",
            precio: 0
        })
        return this.setState({ producto_seleccionado })
    }

    removerAgrupado(i){
        const { producto_seleccionado } = this.state
        producto_seleccionado.agrupados.splice(i,1)
        return this.setState({ producto_seleccionado })
    }

    onSelectedProduct(data) {
        const { producto_seleccionado } = this.state
        producto_seleccionado.agrupados.push({
            titulo: data.titulo,
            sku: data.sku,
            precio: data.precio
        })
        return this.setState({ producto_seleccionado })
    }

    agregarOpcionPersonalizada(posicion_categoria){
        const { producto_seleccionado } = this.state
        producto_seleccionado.categorias[posicion_categoria].opciones.push({
            titulo: "",
            sku: "",
            precio: ""
        })
        return this.setState({ producto_seleccionado })
    }
    
    onSelectedProductDinamic(data, posicion_categoria) {
        const { producto_seleccionado } = this.state
        producto_seleccionado.categorias[posicion_categoria].opciones.push({
            titulo: data.titulo,
            sku: data.sku,
            precio: data.precio
        })
        return this.setState({ producto_seleccionado })
    }

    agregarCategoria() {
        const { nombreCategoria, producto_seleccionado  } = this.state
        if(!nombreCategoria) return toast.error("Escribe el nombre de la categoría")
        if(!producto_seleccionado.categorias) producto_seleccionado.categorias = []
        producto_seleccionado.categorias.push({
            titulo: nombreCategoria,
            opciones: []
        })
        return this.setState({ producto_seleccionado, nombreCategoria: '' })
    }

    removerOpcion(posicion_opcion, posicion_categoria) {
        const { producto_seleccionado } = this.state
        producto_seleccionado.categorias[posicion_categoria].opciones.splice(posicion_opcion,1)
        return this.setState({ producto_seleccionado })
    }
    
    removerCategoria(posicion_categoria) {
        const { producto_seleccionado } = this.state
        producto_seleccionado.categorias.splice(posicion_categoria,1)
        return this.setState({ producto_seleccionado })
    }

    mostrarCategoriasProductoAgrupado() {
        const { producto_seleccionado, user } = this.state
        if(!producto_seleccionado.categorias) return false
        if(Array.isArray(producto_seleccionado.categorias) !== true) return false
        return <div className='mt-2'>
            <h5>{producto_seleccionado.categorias.length} Categorías asignadas</h5>
            {
                producto_seleccionado.categorias.map((cat,icat) => {
                    return <div key={`cat-${icat}`}>
                        <Card className='p-3'>
                            <Row className='mb-3'>
                                <Col md={8}><input className='form-control' value={cat.titulo} /> </Col>
                                <Col md={4}><Button size="sm" variant="outline-danger" onClick={() => this.removerCategoria(icat)}>REMOVER</Button></Col>
                            </Row>
                            
                            <h5>Opciones</h5>
                            <ProductosBuscador token={user._id} onSelectedProduct={(data) => this.onSelectedProductDinamic(data, icat)} />
                            <Button variant="outline-primary" size="sm" className='mb-3' onClick={() => this.agregarOpcionPersonalizada(icat)}>AGREGAR PRODUCTO PERSONALIZADO</Button>
                            {
                                cat.opciones.map((op,io) => {
                                    return <div key={`cat-${icat}-op-${io}`}>
                                        <Card className='p-3'>
                                            <Row>
                                                <Col md={8}><h4>{op.titulo}</h4></Col>
                                                <Col md={4}><Button size="sm" variant="outline-danger" onClick={() => this.removerOpcion(io, icat)}>REMOVER</Button></Col>
                                            </Row>
                                        <Row>
                                            <Col md={8}>
                                                <label className='form-control-label'>Título</label>
                                                <input pos={io} icat={icat} name="titulo" value={op.titulo} className='form-control' onChange={this.handleChangeAgrupadoDinamico} />
                                            </Col>
                                            <Col md={4}>
                                                <label className='form-control-label'>SKU </label>
                                                <input pos={io} icat={icat} name="sku" value={op.sku} className='form-control' onChange={this.handleChangeAgrupadoDinamico} />
                                            </Col>
                                            <Col md={12}>
                                                <label className='form-control-label'>Precio</label>
                                                <input pos={io} icat={icat} name="precio" value={op.precio} type="number" className='form-control' onChange={this.handleChangeAgrupadoDinamico} />
                                            </Col>
                                        </Row>
                                        </Card>
                                    </div>
                                })
                            }
                        </Card>
                    </div>
                })
            }
        </div>
    }

    mostrarOpcionesSegunTipo(){
        const { producto_seleccionado, nombreCategoria, user } = this.state

        if(producto_seleccionado.tipo === "compuesto"){
            return <div className='mb-3'>                
                <h5>Productos integrados</h5>
                <ProductosBuscador token={user._id} onSelectedProduct={(data) => this.onSelectedProduct(data)} />
                {
                    producto_seleccionado.agrupados ? producto_seleccionado.agrupados.map((pro, i) => {
                        return <Card key={`var-${i}`} className='p-3 mb-3'>
                        <Button variant="link" size="sm" className='text-danger' onClick={() => this.removerAgrupado(i)}>REMOVER</Button>
                        <Row>
                            <Col md={8}>
                                <label className='form-control-label'>Título</label>
                                <input pos={i} name="titulo" value={pro.titulo} className='form-control' onChange={this.handleChangeAgrupado} />
                            </Col>
                            <Col md={4}>
                                <label className='form-control-label'>SKU </label>
                                <input pos={i} name="sku" value={pro.sku} className='form-control' onChange={this.handleChangeAgrupado} />
                            </Col>
                            <Col md={12}>
                                <label className='form-control-label'>Precio</label>
                                <input pos={i} name="precio" value={pro.precio} type="number" className='form-control' onChange={this.handleChangeAgrupado} />
                            </Col>
                        </Row>
                    </Card>
                    }) : false
                }

                <Button variant="outline-primary" size='sm' onClick={() => this.anadirAgrupacion()}>AÑADIR PRODUCTO AGRUPADO</Button>
            </div>
        } else if (producto_seleccionado.tipo === "compuesto-dinamico"){
            return <div className='mb-3'>
                <h5>Categorías</h5>
                <Row>
                    <Col><input placeholder='Nombre de la categoría' value={nombreCategoria} onChange={this.handleChanteNombreCategoria} className='form-control' /></Col>
                    <Col><Button size="sm" variant="outline-primary" onClick={() => this.agregarCategoria()}>AGREGAR CATEGORÍA</Button></Col>
                </Row>
                {this.mostrarCategoriasProductoAgrupado()}
            </div>
        }

        return <div>
            
                <div className='form-group'>
                <label className='form-control-label' >Precio</label>
                <input className='form-control mb-3' type="number" name="precio" value={producto_seleccionado.precio} placeholder='Precio del producto' onChange={this.handleChangeEdicionProducto} />
                </div>
                
                <div className='form-group'>
                <label className='form-control-label' >Precio Oferta</label>
                <input className='form-control mb-3' type="number" name="precio_oferta" value={producto_seleccionado.precio_oferta} placeholder='Precio oferta' onChange={this.handleChangeEdicionProducto} />
                </div>
        </div>
    }

    formularioEditarProducto(producto){
        const { actualizando, subiendofoto } = this.state
        return <div>
            <Row>
                <Col md={6}>

                <div className='form-group'>
                <label className='form-control-label' >Tipo de producto</label>
                <select className='form-control mb-3' name="tipo" value={producto.tipo} onChange={this.handleChangeEdicionProducto} >
                    <option value="simple">Simple</option>
                    <option value="compuesto">Compuesto</option>
                    <option value="compuesto-dinamico">Compuesto Dinámico</option>
                </select>
                </div>

                <div className='form-group'>
                <label className='form-control-label' >Nombre</label>
                <input className='form-control mb-3' name="titulo" value={producto.titulo} placeholder='Nombre del producto' onChange={this.handleChangeEdicionProducto} />
                </div>

                <div className='form-group'>
                <label className='form-control-label' >Descripción</label>
                <textarea className='form-control mb-3' type="number" name="descripcion" value={producto.descripcion} placeholder='Descripción del producto' onChange={this.handleChangeEdicionProducto} ></textarea>
                </div>
                <Row>
                    <Col xs={4}>
                    <div className='form-group'>
                    <label className='form-control-label' >Sku</label>
                    <input className='form-control mb-3' name="sku" value={producto.sku} placeholder='SKU Defontana' onChange={this.handleChangeEdicionProducto} />
                    </div>
                    </Col>
                    <Col xs={4}>
                    <div className='form-group'>
                    <label className='form-control-label' >Proteinas</label>
                    <input className='form-control mb-3' name="calorias" value={producto.calorias} placeholder='proteinas' onChange={this.handleChangeEdicionProducto} />
                    </div>
                    </Col>
                    <Col xs={4}>
                    <div className='form-group'>
                    <label className='form-control-label' >Carbohidratos</label>
                    <input className='form-control mb-3' name="carbohidratos" value={producto.carbohidratos} placeholder='Carbohidratos' onChange={this.handleChangeEdicionProducto} />
                    </div>
                    </Col>
                </Row>

                {this.mostrarOpcionesSegunTipo()}
            
                <h5>Opciones</h5>
                {this.formularioOpcionesProducto(producto)}
                    
                </Col>
                <Col md={6}>
                    <h5>Inventario</h5>
                    <label className="form-control-label d-block" >Maneja inventario</label>
                    <Switch onChange={this.handleChangeCheckedInventario} checked={producto.manage_stock} />
                    { producto.manage_stock === true ? <div>
                    <label className="form-control-label d-block" >Cantidad en inventario</label>
                    <input className='form-control mb-3' name="stock" placeholder='STOCK' type="number" value={producto.stock}  onChange={this.handleChangeEdicionProducto} />
                    </div> : false }
                    
                    <Card className='p-3 mb-3'>
                    <h5>Sincronización con Defontana</h5>

                    <label className="form-control-label d-block" >Activar la sincronización</label>
                    <Switch onChange={(e) => this.handleChangeCheckedCustom(e,"sync")} checked={producto.sync} />
                    
                    {
                        producto.sync === true ? <div>
                            <Row>
                                <Col md={4}>
                                    <label className="form-control-label d-block" >Sincronizar título</label>
                                    <Switch onChange={(e) => this.handleChangeCheckedCustom(e,"sync_titulo")} checked={producto.sync_titulo} />
                                </Col>
                                <Col md={4}>
                                    <label className="form-control-label d-block" >Sincronizar precio</label>
                                    <Switch onChange={(e) => this.handleChangeCheckedCustom(e,"sync_precio")} checked={producto.sync_precio} />
                                </Col>
                                <Col md={4}>
                                    <label className="form-control-label d-block" >Sincronizar stock</label>
                                    <Switch onChange={(e) => this.handleChangeCheckedCustom(e,"sync_inventario")} checked={producto.sync_inventario} />
                                </Col>
                            </Row>
                            
                            
                            
                        </div> : false
                    }
                    </Card>

                    <h5>Configuración</h5>
                    {this.formularioConfiguracionesProducto(producto)}
                    <hr className='hr' />
                    <h5>Adicionales</h5>
                    {this.formularioAdicionalesProducto(producto)}

                    <div className='form-group text-left'>
                    <h5>Fotografía del producto</h5>
                    <CargaImagenes onUploaded={(url) => {
                        const { producto_seleccionado } = this.state
                        producto_seleccionado.imagen = url
                        return this.setState({ producto_seleccionado })
                    }} />
                    { subiendofoto ? <Spinner animation='grow' /> : false } 
                    { producto.imagen ? <div>
                        <img alt="producto" className='d-block' src={producto.imagen} width={300} />
                        <Button variant="outline-danger" size="sm" onClick={()=>this.eliminarFotoPro()}>ELIMINAR IMAGEN</Button>
                        </div> : false }
                    </div>

                    <div className='form-group text-left'>
                    <Row>
                        <Col xs={6}>
                        <label className="form-control-label d-block" >Destacar producto</label>
                        <Switch onChange={this.handleChangeCheckedEdicionProducto} checked={producto.destacado} />
                        </Col>
                        <Col xs={6}>
                        <label className="form-control-label d-block" >Activo</label>
                        <Switch onChange={this.handleChangeCheckedActivoProducto} checked={producto.active} />
                        </Col>
                    </Row>
                    </div>
                { actualizando ? <Spinner animation='grow' /> : <Button variant="success" style={{ width: '100%' }} onClick={()=>this.guardarCambiosProducto()}>GUARDAR CAMBIOS</Button> } 

                </Col>
            </Row>
                
                
        </div>
    }

    eliminarFotoCat(){
        const { categoria_seleccionada } = this.state
        categoria_seleccionada.imagen = ''
        return this.setState({ categoria_seleccionada })
    }

    formularioEditarCategoria(){
        const { categoria_seleccionada, actualizando, subiendofoto } = this.state

        return <div>
            <div className='form-group'>
                <label className='form-control-label' >Nombre</label>
                <input className='form-control mb-3' name="title" value={categoria_seleccionada.title} placeholder='Nombre de la categoría' onChange={this.handleChangeEdicionCategoria} />
                </div>
                <div className='form-group text-left'>
                <label className="form-control-label" >Fotografía del producto</label>
                {/* <input className='form-control mb-3' type="file" name="imagen" onChange={this.handleChangeImagenEditarCategoria} /> */}
                <CargaImagenes onUploaded={(url) => {
                    categoria_seleccionada.imagen = url
                    return this.setState({ categoria_seleccionada })
                }} />
                { subiendofoto ? <Spinner animation='grow' /> : false } 
                { categoria_seleccionada.imagen ? <div>
                    <img alt="categoria-imagen" className='d-block' src={categoria_seleccionada.imagen} width={300} />
                    <Button variant="outline-danger" size="sm" onClick={()=>this.eliminarFotoCat()}>ELIMINAR IMAGEN</Button>
                    </div> : false }
                </div>
                { actualizando ? <Spinner animation='grow' /> : <Button variant="success" style={{ width: '100%' }} onClick={()=>this.guardarCambiosCategoria()}>GUARDAR CAMBIOS</Button> } 
        </div>
    }
    
    formularioNuevaCategoria(){
        const { subiendofoto, actualizando, title, imagen } = this.state

        /// CREAR FUNCIONES DE EDITAR NUEVA CATEGORIA Y SUBIR FOTO NUEVA CATEGORIA
        return <div>
            <div className='form-group'>
                <label className='form-control-label' >Nombre</label>
                <input className='form-control mb-3' name="title" value={title} placeholder='Nombre de la categoría' onChange={this.handleChange} />
                </div>
                <div className='form-group text-left'>
                <label className="form-control-label" >Fotografía del producto</label>
                <CargaImagenes onUploaded={(url) => {
                    this.setState({ imagen: url })
                }} />
                { subiendofoto ? <Spinner animation='grow' /> : false } 
                { imagen ? <img alt="nueva-categoria" src={imagen} width={300} /> : false }
                </div>
                { actualizando ? <Spinner animation='grow' /> : <Button variant="success" style={{ width: '100%' }} onClick={()=>this.crearCategoria()}>CREAR</Button> } 
        </div>
    }

    detalleModalEditarCategoria(){
        const { show_modal_categoria } = this.state
        return <Modal show={show_modal_categoria} onHide={()=>this.handleClose()}
        centered
        >
        <Modal.Header closeButton>
          <Modal.Title>Editar Categoría</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            {this.formularioEditarCategoria()}
        </Modal.Body>
        
      </Modal>
    }

    detalleModalNuevaCategoria(){
        const { show_modal_nueva_categoria } = this.state
        return <Modal show={show_modal_nueva_categoria} onHide={()=>this.handleClose()}
        centered
        >
        <Modal.Header closeButton>
          <Modal.Title>Crear Categoría</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            {this.formularioNuevaCategoria()}
        </Modal.Body>
        
      </Modal>
    }

    detalleModalNuevo(){
        const { show_modal_nuevo_producto } = this.state
        return <Modal show={show_modal_nuevo_producto} onHide={()=>this.handleClose()}
        centered
        >
        <Modal.Header closeButton>
          <Modal.Title>Crear Producto</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            {this.formularioNuevoProducto()}
        </Modal.Body>
        
      </Modal>
    }

    detalleModal(){
        const { showmodal, producto_seleccionado } = this.state
        return <Modal show={showmodal} onHide={()=>this.handleClose()}
        centered
        size="xl"
        >
        <Modal.Header closeButton>
          <Modal.Title>Editar Producto</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            {this.formularioEditarProducto(producto_seleccionado)}
        </Modal.Body>
        
      </Modal>
    }
    
    render(){
        return(
            <div className="fluid">
       <Header/>
       <div className="container-fluid">
  <div className="row">
    <main className="col-md-12 ms-sm-auto col-lg-12 px-md-4 leftauto">
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
        <Link to="/establecimientos" className='text-dark' ><i className="fas fa-chevron-left"></i> Volver a establecimientos</Link>
      </div>
      {this.detalleModalNuevaCategoria()}
      {this.detalleModalEditarCategoria()}
      {this.detalleModalNuevo()}
      {this.detalleModal()}
      {this.mostrarDatos()}
    </main>
  </div>
</div>
    </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        miusuario: state.miusuario
    }
}

export default connect(mapStateToProps)(CartaDetalles);