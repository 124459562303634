import { useEffect, useState } from 'react'
import { Button, Col, Row } from 'react-bootstrap'
import Select from 'react-select'
import { toast } from 'react-toastify'
import { urlapi } from '../../lib/backend/data'

const ProductosBuscador = (props) => {

    const [ productosBD, setProductosBD ] = useState([])
    const [ productos, setProductos ] = useState([])
    const [ loading, setLoading ] = useState(true)
    const [ seleccionado, setSeleccionado ] = useState("")
    const token = props.token ? props.token : ""

    const obtenerProductos = async () => {
        const url = `${urlapi}/api/productos/list-complete`
        return fetch(url, {
            method: "GET",
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => res.json())
        .then(res => {
            if(!res){
                toast.error("Sin datos del servidor")
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
            } else if(Array.isArray(res) !== false){
                setProductosBD(res)
                setProductos(res.map(p => ({ value: p._id, label: `${p.sku} ${p.titulo}` })))
            }
            return setLoading(false)
        })
        .catch(error => {
            return setLoading(false)
        })
    }

    const handleChange = (e) => {
        return setSeleccionado(e.value)
    }
    useEffect(() => {
        obtenerProductos()
    }, [])

    const agregarProducto = () => {
        if(!seleccionado) return toast.error("Selecciona un producto antes de agregarlo")
        const i = productosBD.findIndex(p => p._id === seleccionado)
        if(i < 0) return false
        if(props.onSelectedProduct) props.onSelectedProduct(productosBD[i])
        return false
    }

    return <div className='mb-3'>
        <Row>
            <Col md={8}>
            <Select
            placeholder="Buscar un producto"
            options={productos}
            isLoading={loading}
            onChange={handleChange}
            />
            </Col>
            <Col md={4}>
                <Button variant="outline-primary" onClick={() => agregarProducto()}>AGREGAR</Button>
            </Col>
        </Row>
    </div>
}

export default ProductosBuscador