import React, { Component} from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { Dropdown, Navbar, Container } from 'react-bootstrap'
import Nav from 'react-bootstrap/Nav'

class Header extends Component {
    constructor(props){
        super(props)

        this.state = {
            username: '',
            password: '',
            submitted: false,
            loading: false,
            errorLogin: '',
            user: JSON.parse(localStorage.getItem('bumer_bknd_user')),
        }

        this.handleChange = this.handleChange.bind(this)
    }

    handleChange(e){
        const { name, value } = e.target
        return this.setState({ [name]: value })
    }


    render(){
        const { user } = this.state
      return <Navbar expand="lg" >
      <Container fluid>
        <Navbar.Brand ><img alt="logo" id="logo-principal" src="logo-light.png"  /></Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav >
          <Link to="/" className="nav-link">Inicio</Link>
          <Link to="/usuarios" className="nav-link">Usuarios</Link>          
          <Link to="/establecimientos" className="nav-link">Sucursales</Link>          
          </Nav>
        </Navbar.Collapse>
        <div className="justify-content-end">
            <h6>{user.nombres} | <Link to="/login" className="text-danger">Cerrar sesión</Link></h6>
        </div>
      </Container>
    </Navbar>
    }
}

const mapStateToProps = (state) => {
    return {
        miusuario: state.miusuario
    }
}

export default connect(mapStateToProps)(Header);