import React, { Component} from 'react'
import { connect } from 'react-redux'
import { Row, Col, Card, Button } from 'react-bootstrap'
import Header from '../Header'
import Spinner from 'react-bootstrap/Spinner'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import 'moment/locale/es';
import data from '../../lib/backend/data'
class PayPlan extends Component {
    constructor(props){
        super(props)
        this.state = {
            idplan: this.props.match.params.id,
            user: JSON.parse(localStorage.getItem('bumer_bknd_user')),
            listas: [],
            loadingEmails: true,
            filtrousuario: '',
            usuario: {},
            tipousuario: '',
            showcreacion: false,
            hojas: [],
            procesandoexcel: false,
            guardando: false,
            toaststyle: { position: toast.POSITION.BOTTOM_RIGHT },
            filtro_listas_navegacion: '',
            filtro_listas_pedidos: '',
            filtro_listas: '',
            planes_activos: [],
            plan: false

        }

        this.handleChange = this.handleChange.bind(this)
        this.handleChangeUsuario = this.handleChangeUsuario.bind(this)

    }

    handleChange(e){
        const { name, value } = e.target
        return this.setState({ [name]: value })
    }

    handleChangeUsuario(e){
        const { name, value } = e.target
        const { usuario } = this.state
        usuario[name] = value
        if(name==='rut') usuario[name] = value.replace(/[\W_]+/g, "")
        console.log(usuario)
        return this.setState({ usuario: usuario })
    }

    componentDidMount(){
        const { user, idplan } = this.state
        this.getPlan(idplan)
        this.getActivePlans(user._id.toString())
    }

    getActivePlans(iduser){
        this.setState({ loadingEmails: true })
        return fetch(`${data.urlapi}/api/planes/active-plans?iduser=${iduser}`)
        .then(res => res.json())
        .then(res => {
            console.log(res)
            this.setState({ loadingEmails: false, planes_activos: res })
        })
        .catch(error => {
            this.setState({ loadingEmails: false })
            toast.error("Error al consultar la información, intente nuevamente", this.state.toaststyle)
        })
    }

    isBottom(el) {
        return el.getBoundingClientRect().bottom <= window.innerHeight;
      }
      
    componentWillUnmount() {
        document.removeEventListener('scroll', this.trackScrolling);
      }

    trackScrolling = () => {
        const wrappedElement = document.getElementById('root');
        if (this.isBottom(wrappedElement)) {
          //toast.info('Final del listado alcanzado', this.state.toaststyle);
          document.removeEventListener('scroll', this.trackScrolling);
        }
      };

    
    guardarCrear(){
        const { usuario, user } = this.state

        if(!usuario.nombres||!usuario.apellidopaterno||!usuario.apellidomaterno||!usuario.rut||!usuario.fechanacimiento||!usuario.tipo||!usuario.cargo){
            return alert("Todos los campos son requeridos")
        }
        usuario.propietario = user.propietario
        this.setState({ guardandocambios: true })
        return fetch('https://us-west-2.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/sistemahabilidades-sevzv/service/sistemahabilidadesweb/incoming_webhook/web_editarCrearUsuario',{
            method: 'POST',
            body: JSON.stringify(usuario),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(res => res.json())
        .then(res => {
            const texto = usuario._id ? 'Usuario modificado exitosamente' : 'Usuario creado exitosamente'
            this.setState({ guardandocambios: false, textguardado: texto })
            this.getPlan(user)
        })
        .catch(error => {
            this.setState({ guardandocambios: false })
            toast.error("Error al consultar la información, intente nuevamente", this.state.toaststyle)
        })
    }

    getPlan(idplan){
        this.setState({ loadingEmails: true })
        return fetch(`${data.urlapi}/api/planes/details?id=${idplan}`)
        .then(res => res.json())
        .then(res => {
            console.log(res)
            this.setState({ loadingEmails: false, plan: res })
        })
        .catch(error => {
            this.setState({ loadingEmails: false })
            toast.error("Error al consultar la información, intente nuevamente", this.state.toaststyle)
        })
    }

    

    mostrarPlanes(){
        const { loadingEmails, eliminando, plan } = this.state

        if(loadingEmails){
            return <div>
                <Spinner animation='grow' />
                <h3>Cargando información</h3>
            </div>
        }

        return <Card style={{ width: '100%' }}>
        <Card.Body>
<p>A continuación el detalle del plan que seleccionaste, el pago es bajo la modalidad de <b>pago recurrente PAT</b>, puedes cancelar tu suscripción en cualquier momento si lo deseas desde esta plataforma</p>
        
<Row>

<Col md={12} >
            <Card className="mb-3">
        <Card.Body>
            <h3 className="mb-0">{plan.title}</h3>
            <p className="mb-0">{plan.description}</p>
            <h3 >CLP ${Intl.NumberFormat("es-ES").format(plan.price)} <p className="mb-0" style={{ fontSize: 13, display: 'inline-block' }}>{plan.frecuency}</p></h3>
            { eliminando ? <Spinner animation='grow' /> : this.botonSeleccionar(plan._id) }
            </Card.Body>
        </Card>

        <p className="mb-0">Luego de darle click seras redirigido a webpay para confirmar tu suscripción, este sitio no almacena ninguna información de tus tarjetas bancarias</p>
        </Col>

    </Row>
       

        </Card.Body>
      </Card>
    }

    eliminarLista(id){
        const { listas } = this.state
        this.setState({ eliminando: true })
        return fetch(`${data.urlapi}/api/listas/delete?id=${id}`)
        .then(res => res.json())
        .then(res => {
            const i = listas.findIndex(li => li._id === id)
            if(i > -1){
                listas.splice(i,1)
                toast.success('Eliminada exitosamente', this.state.toaststyle)
                this.setState({ listas })
            }
            this.setState({ eliminando: false })
        })
        .catch(error => {
            console.log(error)
            this.setState({ eliminando: false })
            toast.error("Error al consultar la información, intente nuevamente", this.state.toaststyle)
        })

    }

    mostrarPlanesActivos(){
        const { planes_activos } = this.state

        if(planes_activos.length < 1) return <Card className="mb-3">
            <Card.Body >
                <p className="mb-0"><i className="fas text-danger fa-exclamation-circle"></i> Aún no activas un plan, te invitamos a que accedas a un plan para disfrutas de todas las funciones que tenemos preparadas para tí</p>
            </Card.Body>
        </Card>
    }

    mostrarDatosListas(datos, filtro, input){
        
    }

    botonSeleccionar(id){
        const { planes_activos } = this.state
        const i = planes_activos.findIndex(plan => plan._id === id)
        if(i > -1) return <Button size="sm" variant="outline-secondary" >PLAN ACTIVO</Button>

        return <Button size="sm" variant="outline-primary" ><i className="far fa-credit-card"></i> IR AL PAGO</Button>
    }
    
    render(){
        return(
            <div className="fluid">
       <Header/>
       <ToastContainer />   
       <div className="container-fluid">
  <div className="row">
    <main className="col-md-12 ms-sm-auto col-lg-12 px-md-4 leftauto">
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
        <h1 className="h2"> Pago </h1>
      </div>
      {this.mostrarPlanes()}
    </main>
  </div>
</div>
    </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        miusuario: state.miusuario
    }
}

export default connect(mapStateToProps)(PayPlan);